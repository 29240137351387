.HI {
  color: aqua;
}
.parent {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 0px;
}
.div1 {
  grid-area: 1 / 1 / 2 / 2;
}
.div2 {
  grid-area: 1 / 2 / 2 / 3;
}
.div3 {
  grid-area: 1 / 3 / 2 / 4;
}
.div4 {
  grid-area: 1 / 4 / 2 / 5;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  background-color: #ffffff;
}

td,
th {
  text-align: left;
  background-color: #ffffff;
  padding: 10px 0px;
}
.ViewPurchase {
  background: #ffecbf;
  border-radius: 1px;
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #0e0e0e;
  padding: 10px;
}
.PurchaseOrder {
  font-family: gsb;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.purchasecontainer {
  background: #f9f9f9;
  padding: 10px;
}

.noneditable_request {
  background: #fff9e5;
  border-radius: 2px;
  width: 100%;
  font-family: gsb;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  padding: 15px 12px;
}
.addrequest_title {
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #969696;
}
.addrequestInputtag {
  background: #ffffff;
  border-radius: 2px;
  border: none;
  padding: 13px 12px;
  outline: none;
  font-family: gsb;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
}
.row_request {
  padding: 20px;
  background: #ffffff;
}
.EditMaterials {
  background: #fff3cd;
  border-radius: 1px;
  width: fit-content;
  padding: 11px 19px;
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #0e0e0e;
  cursor: pointer;
}

.addrequest_btn {
  background: linear-gradient(93.98deg, #ffe185 6.58%, #f7ba00 105.53%);
  border-radius: 1px;
  width: fit-content;
  padding: 12px 40px;
  font-family: gsb;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #221e1f;
  margin-top: 24px;
  cursor: pointer;
}
.MaterialName_text {
  font-family: gsb;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  padding-top: 22px;
  color: #221e1f;
}
.MaterialName_subtext {
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #221e1f;
  padding: 8px 0px;
}
.addrequestInputtag_drop {
  font-family: gsb;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
}
.ChooseMaterials {
  background-color: white;
  width: 30%;
}
.ChooseMaterials_title {
  font-family: gsb;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  /* Black */
}
.content_mm {
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;

  color: #000000;
}
.content_mm_container {
  background: #f9f9f9;
  border-radius: 2px;
  padding: 20px;
  margin-top: 15px;
}
.add {
  background: #fff3cd;
  border-radius: 2px 0px 0px 2px;
  height: 100%;
  width: 40px;
  /* padding: 8px 0px 0px 8px; */
  cursor: pointer;
  color: #0e0e0e;
}
.sub {
  background: #fff3cd;
  border-radius: 2px 0px 0px 2px;
  height: 100%;
  width: 40px;
  /* padding: 8px 0px 0px 8px; */
  cursor: pointer;
  color: #0e0e0e;
}
.value {
  background: #ffffff;
  border-radius: 2px 0px 0px 2px;
  height: 100%;
  width: 40px;
  text-align: center;
  overflow: none;
  border: none;
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
}
.RequestedCreating {
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 14px;
  color: #909090;
}
.ConfirmMaterials_btn {
  background: linear-gradient(93.98deg, #ffe185 6.58%, #f7ba00 105.53%);
  border-radius: 1px;
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  padding: 15px 0px;
  text-align: center;
  margin-top: 15px;
  cursor: pointer;
}
.checkbox_title {
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
}
.noteMaterialCategory {
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #fe3e3e;
}
.errorRequest {
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #fe3e3e;
}

/* input css */
.search_container {
  width: 100%;
  background: #f9f9f9;
  border-radius: 3px;
  margin-top: 20px;
}
.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.search__input {
  font-family: gsb;
  font-size: inherit;
  background-color: #f9f9f9;
  border: none;
  /* color: #646464; */
  padding: 0.7rem 1rem;
  border-radius: 30px;
  width: 12em;
  transition: all ease-in-out 0.5s;
  margin-right: -2rem;
  width: 100%;
}

.search__input:hover,
.search__input:focus {
  /* box-shadow: 0 0 1em #00000013; */
}

.search__input:focus {
  outline: none;
  /* background-color: #f9f9f9; */
}

.search__input::-webkit-input-placeholder {
  font-weight: 100;
  color: #ccc;
}

.search__input:focus + .search__button {
  /* background-color: #f0eeee; */
}

.search__button {
  border: none;
  background-color: #f9f9f9;
  margin-top: 0.1em;
  margin-right: 10px;
  color: #0e0e0e;
  cursor: none !important;
}

.search__button:hover {
  cursor: pointer;
}

.search__icon {
  height: 1.3em;
  width: 1.3em;
  fill: #b4b4b4;
  color: #0e0e0e;
}
.selectedMatrial {
  background-color: red;
  width: 20%;
}

.TitleIn {
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-transform: uppercase;
  color: #969696;
}
.TitleInsub {
  padding-top: 10px;
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
/* rfq */
.rfqtittle {
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #000000;
}

.Rfqcontainer {
  background: #f6f6f6;
  border-radius: 2px;
  padding: 15px;
}
.vendorNameRFQ {
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
}

.table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #dddddd;
}

.td {
  border: 1px solid #dddddd !important;
  text-align: left;
  padding: 8px;
  color: black !important;
  font-family: gsb;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #0e0e0e;
}

.th {
  border: 1px solid #dddddd !important;
  background-color: #fde8b9;
  text-align: left;
  padding: 8px;
  color: black !important;
  font-family: gb;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 16px;
  color: #0e0e0e;
}
.table > :not(:first-child) {
  border: 1px solid #dddddd !important;
}

.parenttable {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.parenttable1 {
  grid-area: 1 / 1 / 2 / 4;
  padding: 10px;
  border-right: 1px solid #dddddd;
}
.parenttable2 {
  grid-area: 1 / 4 / 2 / 6;
  padding: 10px;
}
.Delivery {
  font-family: gsb;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #0e0e0e;
}
.SubTotaltext {
  padding: 5px;
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #0e0e0e;
}
.scroolView {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(83vh - 94px);
}
.dark {
  font-family: gsb;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #0e0e0e;
}
