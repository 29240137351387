.Notification {
  background-color: #ffff;
  padding: 28px;
}
.Notification_title {
  font-family: gsb;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #0e0e0e;
  margin-bottom: 2px;
}
.Notification_count {
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #fcc200;
}
.Mark_all_as_read {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(91.79deg, #ffe185 -0.46%, #fcc200 104.3%);
  border-radius: 1px;
  padding: 8px;
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #0e0e0e;
  cursor: pointer;
}
.notification_subContainer {
  padding: 25px;
  background: #f8f8f8;
  border-radius: 2px;
  height: calc(90vh - 94px);
  overflow-y: scroll;
}
.dateFormat {
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #919191;
}
.notification_card {
  background: #ffffff;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 19px;
  cursor: pointer;
}
.notification_card_active {
  background: #f8f8f8;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 19px;
  cursor: pointer;
}
.time {
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;

  /* Black */

  color: #0e0e0e;
}
.notification_card_title {
  font-family: gsb;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #0e0e0e;
}

.notification_card_subtitle {
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;

  /* Black */

  color: #0e0e0e;
}
