.MatrialCatalogTab_con {
  width: 100%;
  background: #f9f9f9;
  border-radius: 2px;
  margin-top: 20px;
}
.MatrialCatalogTab_sub {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.MaterialSpecification {
  font-family: gsb;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
}
.configDelete {
  background: #fff6f6;
  border-radius: 2px;
  border: 1px solid #fe3e3e;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  color: #fe3e3e;
  font-family: gm;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
}
.configEditDetails {
  background: linear-gradient(91.79deg, #ffe185 -0.46%, #fcc200 104.3%);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-family: gm;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  cursor: pointer;
}
.config_doc_sec {
  background-color: #fff;
  padding: 20px;
}
.doc_config_select {
  background: #ffffff;
  border-radius: 2px;
  border: none;
  padding: 7px 5px 7px 5px;
  font-family: "gm";
}
.doc_config_select:focus {
  outline: none;
}
.config_document {
  background: #f8f8f8;
  border-radius: 2px;
  padding: 18px;
}
.config_document p {
  font-family: gm;
  font-size: 15px;
  color: #000000;
}
.config_document span {
  font-family: gm;
  font-size: 13px;
  color: #909090;
}
.change_doc_btn {
  background: #ffffff;
  border-radius: 2px;
  color: #909090;
  padding: 10px 15px;
  font-size: 12px;
  font-family: gsb;
}
.view_doc_btn {
  color: #000;
  padding: 10px 15px;
  font-size: 12px;
  font-family: gsb;
  background: #fcc200;
  border-radius: 2px;
}
.config_doc {
  background: #ffffff;
  border-radius: 2px;
  padding: 29px;
  display: flex;
  justify-content: center;
}
.config_doc span {
  color: #000;
  font-family: gsb;
}
.edit_bulk_file,
.download_bulk_file {
  border-radius: 2px;
  padding: 11px 15px;
  cursor: pointer;
}
.edit_bulk_file,
.download_bulk_file {
  border-radius: 2px;
  padding: 11px 15px;
  cursor: pointer;
}
.others_container {
  background: #ffffff;
  border-radius: 2px;
  /* margin-top: 24px; */
}
.edit_bulk_file {
  background: #ffe6ac;
}
.edit_bulk_file1 {
  background: #ffe6ac;
  border-radius: 2px;
  padding: 11px 15px;
}
.download_bulk_file {
  background: #f6f6f6;
}
.bulk_format_txt {
  font-family: "Gsb";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.File_name {
  font-family: "gm";
  color: #000;
}
.dropzone_img {
  text-align: center;
  padding: 20px;
  color: #bdbdbd;
  /* margin-bottom: 20px; */
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fffdf8;
  border: 1px dashed #fcc200;
  border-radius: 2px;
}
.browse_cancel_btn {
  border: 1px solid #f7b314;
  border-radius: 2px;
  background: #fffdf8;
}
.upload_btn {
  background: #f9d788;
  box-shadow: 0px 6px 15px -4px rgb(0 0 0 / 12%);
}
.browse_btn,
.upload_btn,
.browse_cancel_btn {
  box-sizing: border-box;
  padding: 10px;
  font-family: "Gm";
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: #151414;
  border-radius: 2px;
}
.upload_btn_config {
  box-sizing: border-box;
  padding: 10px;
  background-color: #f7b314;
  font-family: "Gm";
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: #151414;
  border-radius: 2px;
}
.browse_btn {
  background: #f9d788;
  box-shadow: 0px 6px 15px -4px rgba(0, 0, 0, 0.12);
}
.drag_drop_txt {
  font-family: "Gsb";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  text-align: center;
  color: #151414;
}
.doc_name {
  color: #000;
  font-family: Gm;
}
.config_material_specs {
  background-color: #f4f4f4;
  border-radius: 5px;
  height: calc(100vh - 275px);
  margin-top: 20px;
}
.config_terms_ {
  background: #f9f9f9;
  border-radius: 2px;
  margin-top: 20px;
  width: 100%;
  height: 54vh;
  overflow: scroll;
}
