.create_po_page {
  height: calc(100vh - 15px);
  overflow-y: scroll;
}

.CopyPurchaseOrder {
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-family: gm;
  font-size: 12px;
  line-height: 14px;
  color: black;
  cursor: pointer;
}
.iconeimage {
  background-color: #3add5e;
  padding: 5px;
  border-radius: 5px;
}
.CreatePurchaseOrder {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px;
  background: linear-gradient(93.98deg, #ffe185 6.58%, #f7ba00 105.53%);
  border-radius: 1px;
  font-family: gm;
  font-size: 12px;
  line-height: 14px;
  color: black;
  cursor: pointer;
}
.PurchageOrder_add {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(93.98deg, #ffe185 6.58%, #f7ba00 105.53%);
  border-radius: 2px;
  height: 45px;
  font-family: gm;
  font-size: 14px;
  line-height: 16px;
  color: black;
  cursor: pointer;
}
.RequestId {
  font-family: gm;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  color: #221e1f;
}
.PurchaseOrderDetails {
  background: #f9f9f9;
  border-radius: 2px;
}
.PurchaseOrderDetails_text {
  font-family: gb;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
}
.PurchaseOrderDetails_subtext {
  font-family: gm;
  font-size: 13px;
  line-height: 15px;
  color: #919191;
}
.PurchaseOrderDetails_subtext1 {
  font-family: gsb;
  font-size: 15px;
  line-height: 18px;
  color: #221e1f;
}
.boxOne {
  background: #f9f9f9;
  border-radius: 2.25px;
  height: 200px;
  width: 30%;
  margin-right: 15px;
}
.boxTwo {
  background: #f9f9f9;
  border-radius: 2.25px;
  height: auto;
  width: 70%;
  padding: 0px 0px 0px 0px;
}

.FullName {
  font-family: gm;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Dark Gray */

  color: #919191;
}
.posubtitle {
  font-family: gsb;
  font-size: 15px;
  line-height: 18px;
  color: #221e1f;
}
.pocontent_container {
  overflow: scroll;
  margin-top: 10px;
  background: #ffffff;
  border-radius: 1.5px;
  padding: 10px;
  font-family: gm;
  font-size: 13px;
  line-height: 22px;

  color: #221e1f;
}
.potable_input {
  border: 0px !important;
  outline: none;
  padding: 10px;
}
/* tr {
  background-color: #f9f9f9 !important;
} */

/* tr:hover {
  background-color: #f9f9f9 !important;
} */
.₹ {
  background: #ffecb5;
  border-radius: 2px 0px 0px 2px;
  /* height: 31px; */
  padding: 10px;
  font-size: 18px;
}
.Included {
  background: #ffffff;
  border-radius: 2px;
  padding: 6px;
}
.RejectReason_container {
  background: #fff4f4;
  border-radius: 4px;
  padding: 25px;
}
.Reject {
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #e92323;
}
.Reason {
  font-family: gsb;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
  margin-top: 5px;
}
.PurchaseOrder_cancel {
  background: #ec1818;
  border-radius: 2px;
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  padding: 15px;
  color: #ffffff;
  cursor: pointer;
}
.CalculateGrandTotal {
  padding: 10px 30px;
  cursor: pointer;
  background: linear-gradient(91.55deg, #ffe185 4.49%, #f7ba00 100.45%);
  border-radius: 2px;
  padding: 15px 40px;
  font-family: gm;
  font-size: 13px;
  line-height: 15px;

  color: #000000;
}
.Delete_bg {
  background: #fff0f0;
  cursor: pointer;
  color: #fe3e3e;
  border-radius: 2px;
  padding: 10px 15px;
}
.notecontainer {
  font-family: gm;
  font-size: 13px;
  line-height: 15px;
  background: #ffffff;

  padding: 5px;
  color: #221e1f;
}
.btn_pdf_up {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(91.79deg, #ffe185 -0.46%, #fcc200 104.3%);
  border-radius: 1.5px;
  width: fit-content;
  padding: 13px 14px;
  margin-top: 20px;
  font-family: gm;
  font-size: 13px;
  line-height: 15px;
  color: #221e1f;
}
.UnloadingChargestext {
  font-family: gm;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
}
.input_fields_tags {
  padding: 0px;
  border: none;
  height: 25px;
  font-family: Gm;
  color: #000;
  width: 120px;
}
.input_fields_tags:focus {
  outline: none;
}
.Gst {
  width: 134px;
  font-family: Gm;
  background-color: #fff;
  /* height: 25px; */
  padding: 10px;
}
.vendor_address_input {
  background-color: #fff;
  border: none;
}
.vendor_address_input:focus {
  outline: none;
}
.download_po_btn {
  background: linear-gradient(90.11deg, #ffdb6c -9.01%, #f7b314 127.45%);
  border-radius: 2px !important;
  font-size: 13px;
  justify-content: center;
  border: none;
  color: #000;
  cursor: pointer;
  padding: 10px 10px;
  /* width: 180px; */
  font-family: gsb;
  cursor: pointer;
}
.PO_heading_ {
  font-size: 13px;
  font-family: Gb;
}
.po_label {
  color: #909090;
  font-size: 13px;
  font-family: Gm;
}
.po_value {
  font-size: 13px;
  color: #000;
  font-family: Gsb;
}
.po__val {
  font-size: 13px;
}
@media print {
  .po_cancel_button {
    visibility: hidden;
  }
  @page {
    size: auto;
    margin: 1mm;
  }
  a[href]:after {
    content: none !important;
  }
  img[src]:after {
    content: none !important;
  }
  .download_po_btn {
    visibility: hidden;
  }
  .po_bread_crumb {
    visibility: hidden;
  }
  body {
    -webkit-print-color-adjust: exact;
    padding: 0px;
  }
  .create_po_page {
    height: -webkit-fill-available;
    overflow-y: scroll;
  }
  .sidlebarcontainer {
    visibility: hidden;
    display: none;
  }
}
/* .sidlebarcontainer */
.po_cancel_button {
  cursor: pointer;
  background: #f6f6f6;
  border-radius: 2px;
  color: #000;
  padding: 8px 20px;
  font-family: Gm;
}
.salutation p {
  font-size: 13px;
  line-height: 28px;
  margin-top: 25px;
  color: #000;
  font-family: Gsb;
}
.border_box {
  border: 1px solid #d5d5d5;
  border-radius: 2px;
}
.nosignature {
  font-family: Gm;
  text-transform: uppercase;
  padding: 10px;
  font-size: 10px;
  background: #fde8b9;
  text-align: center;
  width: 100%;
}
.border_none {
  border: none !important;
}
.border_td {
  border-right: 1px solid lightgrey;
}
.new_po {
  background: #ccffcc;
  border-radius: 1px;
  color: #107010;
  padding: 10px;
  width: 60%;
  text-align: center;
}
.canceled_po {
  color: #e92323;
  background: #ffe6e6;
  padding: 10px;
  width: 60%;
  text-align: center;
  border-radius: 1px;
}
