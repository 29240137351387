.Title_container {
  background: #ffecbe;
  padding: 15px;
  font-family: gsb;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #0e0e0e;
}

.parentvandorGridone {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 0px;
}

.poProjects .css-1s2u09g-control {
  border: 1px solid #d0d0d0 !important;
}

.Material__filter {
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;

  /* Black */

  color: #221e1f;
}

.selectMul:focus {
  width: 100%;
  border: 0.959133px solid #d0d0d0;
  border-radius: 2px;
  width: -moz-fit-content;
  width: fit-content;
  /* padding: 15px; */
  outline: none;
  font-family: gsb;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  display: flex;
}

.Upload_Document {
  margin-top: 20px;
  background: linear-gradient(93.98deg, #ffe185 6.58%, #f7ba00 105.53%);
  border-radius: 2px;
  padding: 15px;
  border-radius: 1px;
  font-family: "gsb";
  font-size: 13px;
  line-height: 15px;
  color: #221e1f;
  text-align: center;
  cursor: pointer;
}

.AddvendorInput {
  border: 0.959133px solid #d0d0d0;
  border-radius: 2px;
  width: 100%;
  padding: 15px;
  outline: none;
  font-family: gsb;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;
}
.AddvendorInputselect {
  border: 0.959133px solid #d0d0d0;
  border-radius: 2px;
  width: fit-content;
  /* padding: 15px; */
  outline: none;
  font-family: gsb;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;
}

.up_button {
  border-radius: 1px;
  background: var(--Brand-Yellow, #fcc200);
  position: absolute;
  top: 0;
  right: 0;
  padding: 11px;
}

.AddvendorInputpan {
  border: 0.959133px solid #d0d0d0;
  border-radius: 2px;
  width: 100%;
  text-transform: uppercase;
  padding: 15px;
  outline: none;
  font-family: gsb;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: center;
}

.titleInput {
  font-family: gb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  /* color: #221e1f; */
  color: #969696;
}

.PluseNO {
  background: #eaeaea;
  font-family: gb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #0e0e0e;
  padding: 16px 8px;
  margin: 0px;
}

.div1 {
  grid-area: 1 / 1 / 2 / 2;
  /* background-color: rebeccapurple; */
}

.div2 {
  grid-area: 1 / 2 / 2 / 3;
  /* background-color: blue; */
}

.div3 {
  grid-area: 1 / 3 / 2 / 4;
  /* background-color: yellow; */
}

.div4 {
  grid-area: 1 / 4 / 2 / 5;
  /* background-color: darkblue; */
}

.popupcontainer_body {
  width: 25%;
  background-color: white;
  padding: 10px;
}

.parentvandorGridtwo {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 0px;
}

.parentvandorGridtwo1 {
  grid-area: 1 / 1 / 2 / 2;
}

.parentvandorGridtwo2 {
  grid-area: 1 / 2 / 2 / 3;
}

.parentvandorGridtwo3 {
  grid-area: 1 / 3 / 2 / 5;
}

.selectMul {
  margin-top: 8px !important;
}

.selectMul1 {
  margin-top: 0px !important;
}

.selectMul1 {
  margin-top: 0px !important;
}

.selectMul .css-1s2u09g-control {
  /* padding: 4px 0px; */
  height: 46px;
}

/* .selectMul .css-1s2u09g-control:focus {
  padding: 4px 0px !important;
  border-color: gray !important;
}
.css-1s2u09g-control:hover {
  border-color: gray !important;
} */

.vendorbankparentone {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 0px;
}

.vendorbankparentone1 {
  grid-area: 1 / 1 / 2 / 2;
  /* background-color: red; */
}

.vendorbankparentone2 {
  grid-area: 1 / 2 / 2 / 5;
  /* background-color: aqua; */
}

.bankvendor {
  background: #f8f8f8;
  border-radius: 2px;
  padding: 16px 14px;
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #717171;
}

.vendorbankparenttwo {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 0px;
}

.vendorbankparenttwo1 {
  grid-area: 1 / 1 / 2 / 3;
}

.vendorbankparenttwo2 {
  grid-area: 1 / 3 / 2 / 4;
}

.vendorbankparenttwo3 {
  grid-area: 1 / 4 / 2 / 5;
}

.scrollvendor {
  overflow-y: scroll;
  height: calc(95vh - 94px);
}

.radioBTN {
  display: flex !important;
  align-items: center !important;
}

.invoice_date {
  font-family: gsb;
  font-style: normal;
  /* font-weight: 500; */
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.uplode_file_dot_border {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dotted #908f8f;
  width: 50%;
  height: 271.6px;
  /* margin: auto; */
  margin-top: 16px;
}

.uplode_file_text {
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;
}

.AddVendor_btn {
  background: linear-gradient(90.11deg, #ffdb6c -9.01%, #f7b314 127.45%);
  border-radius: 2px;
  margin-top: 20px;
  padding: 15px;
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #0e0e0e;
}

.uplode_file_btn {
  background: #f7b314;
  border-radius: 3px;
  margin-top: 15px;
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #0e0e0e;
}

.rowContainer {
  background: #f6f6f6;
  border-radius: 2px;
}

.closeRow {
  background: #ffffff;
  border-radius: 1px;
  width: fit-content;
  padding: 5px;
}

.AddRow {
  background: #ffffff;
  padding: 10px;
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #000000;
}

.upload {
  font-family: msb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;

  /* Black */

  color: #0e0e0e;
}

.SaveDetails {
  background: #f7b314;
  padding: 10px;
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #000000;
}

.eroorMSG {
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;

  /* Black */

  color: #d15656;
}

.image_scrool {
  overflow-y: scroll;
  max-height: 275px;
}

.image_scrool::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.image_scrool::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.image_scrool::-webkit-scrollbar-thumb {
  background: #f7b314;
  border-radius: 10px;
}

/* Handle on hover */
.image_scrool::-webkit-scrollbar-thumb:hover {
  background: #f7b314;
  border-radius: 10px;
}

.path {
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
}

.PersonalInfo {
  background: #f9f9f9;
  border-radius: 2px;
  padding: 20px;
}

.PersonalInfo_title {
  font-family: gsb;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.parentPersonalInfo {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
}

.parentPersonalInfo1 {
  grid-area: 1 / 1 / 2 / 2;
  /* background-color: red; */
}

.parentPersonalInfo2 {
  grid-area: 1 / 2 / 2 / 3;
  /* background-color: blue; */
}
.parentPersonalInfo21 {
  grid-area: 1 / 2 / 2 / 4;
}

.parentPersonalInfo3 {
  grid-area: 1 / 3 / 2 / 4;
  /* background-color: purple; */
}

.parentPersonalInfo4 {
  grid-area: 1 / 4 / 2 / 5;
  /* background-color: rgb(147, 98, 147); */
}

.parentPersonalInfo5 {
  grid-area: 1 / 5 / 2 / 6;
  /* background-color: yellow; */
}

.parentPersonalInfo1_text {
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #969696;
}

.parentPersonalInfo1_subtext {
  font-family: gsb;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #221e1f;
}

.parentPersonalInfosecond {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 11px;
  grid-row-gap: 0px;
}

.parentPersonalInfosecond1 {
  grid-area: 1 / 1 / 2 / 2;
}

.parentPersonalInfosecond2 {
  grid-area: 1 / 2 / 2 / 3;
}

.parentPersonalInfosecond3 {
  grid-area: 1 / 3 / 2 / 6;
}

.parentPersonalInfosecond4 {
  grid-area: 2 / 1/ 3 / 3;
}

.parentPersonalInfosecond5 {
  grid-area: 2 / 3 / 3 / 3;
}

.parentPersonalInfosecond6 {
  grid-area: 2 / 4/ 3 / 4;
}

.parentPersonalInfosecond11 {
  width: 100%;
}

.parentPersonalInfosecond11 {
  width: 100%;
}

.PaymentTerm {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 11px;
  grid-row-gap: 0px;
}

.PaymentTerm1 {
  grid-area: 1 / 1 / 2 / 2;
}

.PaymentTerm2 {
  grid-area: 1 / 2 / 2 / 6;
}

.scroolView {
  overflow-y: scroll;
  height: calc(83vh - 94px);
}

.GSTcrertificate {
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #221e1f;
}

.Download {
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #717171;
}

.matrialBox {
  background: #ececec;
  border-radius: 2px;
  padding: 9px;
  width: fit-content;
}

.viewPO_btn {
  width: 78px;
  height: 34px;
  border-radius: 2px;
  background: #f8f8f8;
  font-family: gm;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  color: #000000;
}

.dropdown_action {
  background: linear-gradient(91.79deg, #ffe185 -0.46%, #f7ba00 104.3%);
  border-radius: 1px;
  font-family: "gm";
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  height: 34px;
}

.PO_heading {
  font-family: "gm";
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  color: #221e1f;
  margin-left: 10px;
}

.PO_border {
  border-bottom: 1px solid #d5d5d5;
  margin-top: 31px;
}

.searchbar {
  width: 1030px;
  height: 36px;
  border-radius: 1px;
  border: none;
  background: #f9f9f9;
  color: #000000;
  outline: none;
  padding-left: 36px;
  font-family: Gm;
  font-size: 12px;
}

.searchIcon1 {
  position: absolute;
  top: 12px;
  left: 20px;
}

.searchBtn {
  width: 90px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 18px;
  border-radius: 0px, 1px, 1px, 0px;
  background: #ffebaa;
  font-family: Gm;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  color: #000000;
  cursor: pointer;
}

.filter_btn {
  width: 75px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1px;
  background: #f9f9f9;
  font-family: Gm;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  color: #909090;
  cursor: pointer;
}

.tablecontent {
  /* width: 1148px; */
  height: calc(100vh - 250px);
  overflow-y: scroll;
  border-radius: 2px, 2px, 0px, 0px;
  border: 1px;
}

.table_head {
  border-radius: 2px 2px 0px 0px !important;
  background: linear-gradient(99deg, #606060 -7.16%, #0e0e0e 109.49%) Im !important;
}

.filter_title {
  color: #000;
  font-family: "Gsb";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.filter_heading {
  color: var(--Black, #221e1f);
  font-family: "Gm";
  font-size: 13px;
  font-weight: 300;
}

.dropdown_fields {
  width: 100%;
  height: 45px;
  border-radius: 2px;
  background: var(--Light-Gray, #f8f8f8);
  outline: none;
  border: none;
  padding: 0px 0px 0px 10px;
  font-family: "Gm";
  color: gray;
}

.resetAll_btn {
  width: 163px;
  height: 45px;
  border-radius: 2px;
  background: var(--Light-Gray, #f2f2f2);
  font-family: Gm;
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
  color: #000;
}

.apply_btn {
  width: 163px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 2px;
  background: linear-gradient(94deg, #ffe185 6.58%, #f6ba00 105.53%);
  font-family: Gm;
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
  color: #000;
}

.datePicker .rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 12px !important;
}

.datePicker
  .rs-picker-daterange
  .rs-picker-toggle.rs-btn
  .rs-picker-toggle-caret {
  top: 11px !important;
}

.datePicker
  .rs-picker-daterange
  .rs-picker-toggle.rs-btn
  .rs-picker-toggle-clean {
  top: 11px;
}

.multidrop .css-n8yddi-control {
  height: 45px !important;
}

.table_row {
  height: 65px;
  color: var(--Black, #221e1f);
  text-align: center;
  font-family: "Gm";
  font-size: 13px;
  font-weight: 300;
}

.newPO {
  width: 82px;
  height: 31px;
  flex-shrink: 0;
  border-radius: 1px;
  background: #cfc;
  color: #107010;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Gm";
  font-size: 13px;
  font-weight: 300;
}

.PO_cancelled {
  width: 115px;
  height: 31px;
  flex-shrink: 0;
  border-radius: 1px;
  background: #ffe5e5;
  color: #e92323;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Gm";
  font-size: 13px;
  font-weight: 300;
}

/* Vendor Upload Bullk */
.Vendor_BulkContainer_Box {
  width: 384px;
  height: 270px;
  background-color: #FFFFFF;
  padding: 24px;
  border-radius: 2px;
}

.Vendor_ContentBox {
  display: flex;
  justify-content: space-between;


}

.Vendor_Bulk_heading {
  color: #000;
  font-family: "gb";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.Vendor_bulk_paraBox {
  margin-top: 12px;
}

.Vendor_Bulk_Para_Span {
  color: var(--Black, #221E1F);
  font-family: "gb";
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.V3vendor{
  padding-left: 16px;

}
.Vendor_Bulk_Para {
  color: var(--Black, #221E1F);
  font-family: "gsb";
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 4px;
}

.vendor_BultButtonBox {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

}

.Vendor_BulkCancel {
  width: 163px;
  height: 45px;
  border-radius: 2px;
  background: var(--Light-Gray, #F2F2F2);
}

.Vendor_Bulkcontinue {
  width: 163px;
  height: 45px;
  border-radius: 2px;
  background: linear-gradient(94deg, #FFE185 6.58%, #F6BA00 105.53%);
}

.Vendor_BulkBtn {
  color: #000;
  text-align: center;
  font-family: "gsb";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
