* {
  margin: 0;
  padding: 0;
}

body {
  padding: 0px !important;
  overflow: hidden;
}

/* body html {
  position: fixed;
  width: 100%;
  overflow: scroll;
} */

.login_body {
  width: 100%;
  display: flex;
  font-family: gm;
}

.login_left_div {
  position: relative;
  width: 30%;
  background-image: url("../src/Assets/Img/login_bg.jpg");
  /* The image used */
  background-color: #cccccc;
  /* Used if the image is unavailable */
  height: 100vh;
  /* You must set a specified height */
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
  /* Resize the background image to cover the entire container */
}

.login_right_div {
  width: 70%;
  height: 100vh;
}

.Form_body {
  height: 100vh;
}

.nodata {
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;

  color: #000000;
}

.login_input_fileds {
  background: #f5f5f5;
  border-radius: 5px;
  padding: 10px 15px;
  border: none;
  font-size: 14px;
}

.login_buton {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border: none;
  color: #fff;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  color: #000;
}

.addrequest_container {
  width: 100%;
  background-color: #f9f9f9;
  padding: 23px;
}

.logintext {
  font-family: gb;
  font-size: 37.5px;
  line-height: 44px;
  text-transform: capitalize;

  color: #000000;
}

.welcome_txt {
  color: #fff;
  position: absolute;
  bottom: 50px;
  left: 25px;
}

.welcome_txt h3 {
  color: #fcc314;
}

.search_div {
  background: #efefef78;
  border-radius: 5px;
  padding: 8px;
  /* width: 45%; */
  display: flex;
  align-items: center;
}

.top_menu_search {
  border: none;
  background: #efefef78;
  width: 90%;
  font-size: 14px;
}

.top_menu_search:focus {
  outline: none;
}

.version_tag {
  display: flex;
  align-items: center;
  background-color: #fcc216;
  padding: 5px;
  color: #000;
  font-family: Gm;
}

.version_div {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 10px;
}

.loadergif {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
}

#filter_range {
  background-color: #f8f8f8 !important;
}

.rs-picker-daterange-menu {
  z-index: 10000000000 !important;
}

.rs-picker-menu {
  overflow-y: scroll !important;
}

.filter_op_btns {
  cursor: pointer;
  padding: 14px;
  width: 100%;
  font-family: "gm";
  color: #000;
  border-radius: 2px;
  text-align: center;
  background-color: #f8f8f8 !important;
}

.filter_op_btns1 {
  cursor: pointer;
  padding: 14px;
  width: 100%;
  font-family: "gm";
  color: #000;
  border-radius: 2px;
  text-align: center;
  background: linear-gradient(
    162.29deg,
    #ffe185 -6.85%,
    #f7ba00 100.08%
  ) !important;
}

.label_textfields,
.select_label {
  font-family: gm;
  font-size: 13px;
}

.filter_usertype {
  border-radius: 0px;
  padding: 13px 16px 13px 11px !important;
  background: #f8f8f8 !important;
  text-align: start !important;
  font-family: gm !important;
  width: 100%;
  border: 1px solid #e5e5ea;
  color: #8e8e93;
}

.filter_usertype:focus {
  outline: none;
}

/* style.css */

@font-face {
  font-family: "gm";
  src: local("gm"),
    url("../src/Assets/Fonts/GilroyFont/Gilroy-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "gr";
  src: local("gr"),
    url("../src/Assets/Fonts/GilroyFont/Gilroy-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "gsb";
  src: local("gsb"),
    url("../src/Assets/Fonts/GilroyFont/Gilroy-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "gb";
  src: local("gb"),
    url("../src/Assets/Fonts/GilroyFont/Gilroy-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Msb";
  src: local("Msb"),
    url("../src/Assets/Fonts/mont/Montserrat-Medium.ttf") format("truetype");
}

/* dashboard tab */
.sidlebarcontainer {
  width: 15rem;
  height: 100vh;
  background-color: #f9f9f9;
}

.dashboardtabsection {
  flex: 1;
}

.cendrolImgadmin {
  width: 72%;
}

.dashbordScreenContainer {
  padding: 25px;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
}

.dashboardtitle {
  font-family: gb;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #221e1f;
}

.dashtab {
  background-color: #f9f9f9;
  padding: 16px;
  width: 32%;
}

.dashtabtxt {
  font-family: gb;
  font-size: 20px;
  line-height: 23px;

  color: #000000;
}

.dashtabsubtxt {
  font-family: gm;
  font-size: 13px;
  line-height: 14px;

  color: #000000;
}

.RequestCreated {
  font-family: gb;
  font-size: 20px;
  line-height: 23px;

  color: #000000;
}

.selectoption:focus {
  outline: none !important;
  border-color: black;
  box-shadow: none;
}

.date_picker:focus {
  outline: none !important;
  border-color: black;
  box-shadow: none;
}

/* .form-select {
  font-family: gm;
  font-size: 13px;
  line-height: 15px;
  padding: 8px 32px 8px 13px;
  color: #221e1f;
} */
.dashboardgraph {
  margin-top: 20px;
  width: 100%;
  height: 390px;
  background-color: rgb(92, 90, 90);
}

/* end dashboard tab */
/* request start */
.addrequestBTN {
  padding: 10px 20px;
  background: linear-gradient(93.98deg, #ffe185 6.58%, #f7ba00 105.53%);
  border-radius: 1px;
  font-family: gm;
  font-size: 12px;
  color: #000000;
  cursor: pointer;
}

.allrequeststab {
  font-family: gm;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #0e0e0e;
  padding: 11px 43px 11px 52px;
  border-bottom: 2px solid #ebebeb;
  cursor: pointer;
}

.allReceivedtab {
  font-family: gsb;
  font-size: 13px;
  line-height: 15px;

  color: #0e0e0e;
  padding: 11px 0px 11px 0px;
  text-align: center;
  border-bottom: 2px solid #ebebeb;
  cursor: pointer;
}

.allrequeststabactive {
  font-family: gsb;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #0e0e0e;
  padding: 11px 43px 11px 52px;
  border-bottom: 2px solid #f7ba00;
  cursor: pointer;
}

.allReceivedtabactive {
  font-family: gsb;
  font-size: 13px;
  line-height: 15px;

  color: #0e0e0e;
  padding: 11px 0px 11px 0px;
  border-bottom: 2px solid #f7ba00;
  text-align: center;
  cursor: pointer;
}

.empty {
  width: 100%;
  padding: 11px 45px 11px 45px;
}

.tabsrequest {
  margin: 0;
  padding: 0;
  color: yellow;
}

.allReceivedtabempty {
  padding: 8px;
  border-bottom: 2px solid #ebebeb;
  color: transparent;
}

.inner_table_div {
  background-color: #f5f5f5;
  height: calc(90vh - 200px);
  overflow-y: scroll;
  border-radius: 5px;
}

.table td {
  padding: 12px;
  vertical-align: middle;
  /* vertical-align: top; */
  border-top: none;
  font-size: gm;
  font-size: 13px;
}

.table th {
  border-bottom: 1px solid #dee2e6;
  border-top: none;
  font-family: "gm";
  font-weight: 100;
  padding: 15px;
  font-size: 13px;
  line-height: 15px;
  cursor: auto;
  color: #ffffff;
}

.headers_tag {
  background: linear-gradient(
    99.28deg,
    #606060 -7.16%,
    #0e0e0e 109.49%
  ) !important;
  color: #fff;
  position: sticky;
  top: 0;
  /* z-index: 10; */
}

.headers_tag:hover {
  background: linear-gradient(
    99.28deg,
    #606060 -7.16%,
    #0e0e0e 109.49%
  ) !important;
  color: #fff;
}

.headers_tags {
  background: #e6e6e6;
  color: #fff;
  /* position: sticky; */
  top: 0;
  z-index: 10;
}

.headers_tags:hover {
  background: #e6e6e6;
}

.headers_tags_new {
  background: #fde8b9;
  color: #fff;
  /* position: sticky; */
  top: 0;
  z-index: 10;
}

.headers_tags_new:hover {
  background: #fde8b9;
}

.vals:hover {
  background: #fff !important;
  color: #221e1f;
}

.vals {
  background: #f9f9f9;
  border: 1.2px solid #e6e6e6;
  border-radius: 2px;
  font-family: "gm";
  font-size: 13px;
  line-height: 15px;
  color: black;
}

.vals_new {
  background: #f9f9f9;
  border: 1.2px solid #e6e6e6;
  border-radius: 2px;
  font-family: "gm";
  font-size: 13px;
  line-height: 15px;
  color: #000;
}

.active_dot {
  width: 10px;
  height: 10px;
  background-color: #fe0c0c;
  border-radius: 50%;
  margin-right: 4px;
}

.med_pri {
  width: 10px;
  height: 10px;
  background-color: #ff7410;
  border-radius: 50%;
  margin-right: 4px;
}

.low_pri {
  width: 10px;
  height: 10px;
  background-color: #fcc314;
  border-radius: 50%;
  margin-right: 4px;
}

.green {
  color: #3add5e;
}

.red {
  color: #fe3e3e;
}

.select-dropdown,
.select-dropdown * {
  margin: 0;
  padding: 0;
  position: relative;
  box-sizing: border-box;
}

.select-dropdown1,
.select-dropdown1 * {
  margin: 0;
  padding: 0;
  position: relative;
  box-sizing: border-box;
}

.select-dropdown {
  position: relative;
  background-color: #fff;
  color: #000;
  font-family: gm;
  border-radius: 4px;
  /* width: 30%; */
}

.select-dropdown1 {
  position: relative;
  background-color: #fff;
  color: #000;
  font-family: gm;
  border-radius: 4px;
  /* width: 30%; */
}

.select-dropdown1 select {
  border-radius: 2px;
  font-size: 1rem;
  font-weight: normal;
  cursor: pointer;
  max-width: 100%;
  width: 100%;
  padding: 5px 8px 5px 10px;
  border: 1px solid rgb(118, 118, 118), rgb(133, 133, 133);
  background-color: #f5f5f5;
  -webkit-appearance: none;
  appearance: none;
}

.select-dropdown select {
  border-radius: 2px;
  font-size: 12px;
  font-weight: normal;
  font-family: Gm;
  cursor: pointer;
  max-width: 100%;
  width: 100%;
  padding: 8px 8px 8px 10px;
  border: none;
  background-color: #f5f5f5;
  -webkit-appearance: none;
  appearance: none;
}

.select-dropdown select:active,
.select-dropdown select:focus {
  outline: none;
  box-shadow: none;
}

.select-dropdown:after {
  content: "";
  position: absolute;
  cursor: pointer;
  top: 50%;
  right: 8px;
  width: 0;
  height: 0;
  margin-top: -2px;
  border-top: 5px solid #000;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

.select-dropdown select:hover {
  background: linear-gradient(162.29deg, #ffe185 14.78%, #f7ba00 95.75%);
}

.Activeuser {
  width: fit-content;
  height: 25px;
  background: #3add5e;
  border-radius: 1px;
  padding: 5px 6px 2px 12px;
  font-family: gm;
  font-size: 13px;
  line-height: 15px;

  color: #ffffff;
}

.popup_icon_center,
.popup_text_center {
  flex-direction: row;
  text-align: center;
}

.popup_unlinkbankdetails_icon,
.popup_linkbankdetails_icon {
  font-size: 50px !important;
}

.popup_unlinkbankdetails_icon {
  color: #fe3e3e !important;
}

.popup_linkbankdetails_icon {
  color: #3add5e !important;
}

.popup_unlinkbankdetails_icon {
  color: #ff3535 !important;
  font-size: 50px !important;
}

.popup_addbankdetails_text {
  font-family: "Gsb";
  font-style: normal;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #000000;
}

.head_modal {
  height: 5px;
  background: #e64b4b;
  border-radius: 2px 2px 0px 0px;
}

.popup_btn_unlinkaccount,
.popup_btn_linkaccount,
.popup_btn_reupload {
  color: #ffffff;
  background: #fe3e3e;
  border-radius: 2px;
  font-family: "Gsb";
  width: 100%;
  padding: 10px;
  border: none;
  font-size: 14px;
  line-height: 16px;
}

.popup_btn_linkaccount {
  background: #3add5e;
}

.editinputuser,
.editdisable,
.popup_btn_close {
  color: #221e1f;
  background: #f4f4f4;
  border-radius: 2px;
  border-radius: 5px;
  font-family: "Gsb";
  width: 100%;
  padding: 12px 18px;
  border: none;
  font-size: 13px;
  line-height: 15px;
}

/* .popup_btn_close:hover {
  color: #fff;
} */

.editinputuser,
.editselectuser {
  outline: none;
  border: none;
  width: 100%;
  font-family: "gm";
  font-size: 13px;
  line-height: 15px;
  color: #221e1f;
}

.editinputuser,
.editdisable {
  padding: 9px;
}

.editdisable {
  background: #fff9e5;
}

.editinputuser {
  padding: 14px;
}

.editselectuser,
.numbereditinput {
  padding: 8px;
}

.cardtitle {
  font-family: "gm";
  font-size: 12px;
  line-height: 14px;
  color: #969696;
  padding-bottom: 6px;
}

.cardsubtitle {
  font-family: gsb;
  font-size: 14px;
  line-height: 16px;
  color: #221e1f;
}

.PersonalInfoTitle {
  font-family: gsb;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.details_area {
  background: #f9f9f9;
  border-radius: 2px;
}

.selectdrop select {
  background: linear-gradient(
    162.29deg,
    #ffe185 14.78%,
    #f7ba00 95.75%
  ) !important;
}

.add_req_btn {
  padding: 5px 10px;
  height: 35px;
  background: linear-gradient(162.29deg, #ffe185 14.78%, #f7ba00 95.75%);
  border: navajowhite;
  font-family: "gsb";
  border-radius: 5px;
  color: #000;
}

.searchrequst {
  flex: 1;
  /* background: #f9f9f9; */
}

.serchbuttonrequest,
.filterbutton {
  width: fit-content;
  justify-content: center;
  padding: 11px;
  gap: 5px;
  cursor: pointer;
  border-radius: 0px 1px 1px 0px;
  font-family: "gm";
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #000000;
}

.serchbuttonrequest {
  background: #ffebaa;
}

.filterbutton {
  background: #f9f9f9;
}

.btn-close {
  font-size: 10px !important;
}

.filterrequest {
  cursor: pointer;
  background: #f9f9f9;
  border-radius: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: gm;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #000000;
  padding: 0px 16px 0px 16px;
}

.inputsearch {
  outline: none;
  fill: #f9f9f9;
  background-color: #f9f9f9;
  border: 0px;
  padding-left: 31px;
  padding: 8px 14px 8px 14px;
  font-family: "gm";
  font-size: 12px;
  line-height: 14px;
  color: #a9a9aa;
}

/* request end */
/* manage user start */
.admindashsearch {
  background: rgba(239, 239, 239, 0.47);
  padding: 5px 7px 5px 7px;
}

.admindashsearchinput {
  font-family: gm;
  font-size: 12px;
  line-height: 14px;

  color: #757575;
  background: transparent;
  outline: none;
  border: none;
}

.Notifications {
  background: #f8f8f8;
  padding: 5px;
  border-radius: 50%;
}

.imgavtaradmin {
  width: 20%;
}

.adminprofile {
  display: flex;
  align-items: center;
}

.adduserbtn {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  gap: 10px;
}

.userbtn {
  background: linear-gradient(93.98deg, #ffe185 6.58%, #f7ba00 105.53%);
  border-radius: 1px;
  font-family: "gm";
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  cursor: pointer;
  padding: 11px 16px;
  display: flex;
  gap: 10px;
}

.user_active,
.user_inactive {
  font-family: "gm";
  font-size: 13px;
  line-height: 15px;
  border-radius: 2px;
  padding: 7px 16px;
  width: 100%;
}

.user_active {
  background: #ebfcef;
  border-radius: 1px;
  color: #3add5e;
  /* width: 110px; */
  padding: 10px;
}

.user_inactive {
  color: #fe3e3e;
  background: #ffecec;
  width: 110px;
}

.dropdown-item:active,
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: rgb(244, 244, 244) !important;
}

.action .dropdown-menu {
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.09) !important;
  border-radius: 2px;
  border: none;
  top: 5px !important;
}

.action .dropdown-item {
  font-family: "gm";
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.dropdown_action:focus {
  outline: none;
  box-shadow: none !important;
}

.dropdown_action::after {
  margin-left: 1.255em;
}

.dropdown-toggle[aria-expanded="true"]:after {
  transform: rotate(180deg);
}

.user_modal_head_txt {
  font-family: "gb";
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.bluck_input,
.bluck_select {
  padding: 15px !important;
  font-family: "gm";
  font-size: 12px;
  line-height: 14px;
  color: #221e1f;
  border-radius: 2px;
  border: none;
  background-color: #f8f8f8;
}

.bluck_input:focus,
.bluck_select:focus {
  box-shadow: none !important;
  font-family: "gsb";
  font-size: 13px;
  line-height: 15px;
  color: #221e1f;
  background-color: #f8f8f8;
}

.details_txt {
  font-family: "gsb";
  font-size: 18px;
  line-height: 21px;
  color: #221e1f;
}

.fontmatrialbtn {
  cursor: pointer;
  font-family: gm !important;
  font-size: 12px !important;
  line-height: 14px !important;
}

.tableHead {
  border-bottom: 1px solid black;
  width: 20%;
  text-align: center;
  padding: 12px 0px 12px 0px;
  color: #000;
  font-family: gm;
  font-size: 13.5px;
  line-height: 16px;
  text-align: center;
  color: #221e1f;
}

.tablebody {
  width: 20%;
  text-align: center;
  padding: 22px 0px 22px 0px;
  color: #000;
  font-family: gm;
  font-size: 13.5px;
  line-height: 16px;
  text-align: center;
  color: #969696;
}

.form_invoice_upload {
  padding: 5px;
}

.form_invoice_upload1 {
  padding: 38px;
  width: 75%;
  background-color: #fff;
  margin: auto;
  border-radius: 2px;
  margin-top: 10%;
}

.tick_mark {
  color: #3add5e;
  width: 2em !important;
  height: 2em !important;
}

.inner_table_div {
  background-color: #f5f5f5;
  height: calc(90vh - 200px);
  overflow-y: scroll;
  border-radius: 5px;
}

tr:hover .partner_back_btn {
  color: #fcc216;
}

.partner_back_btn {
  color: #969696;
  font-size: 20px;
}

.ViewDetails {
  background: #f4f4f4;
  border-radius: 3.75px;
  width: fit-content;
  margin: auto;
  padding: 9px 10px 9px 10px;
}

.ViewDetailstab {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 3.75px;
  width: fit-content;
  margin: auto;
  padding: 6px 8px 6px 8px;
}

.select_project_stage {
  width: 100%;
  height: 40px;
  border: 1px solid #ced4da;
  background: #f8f8f8;
  font-family: gm;
  font-size: 14px;
  border-radius: 0.25rem;
}

.select_project_stage:focus {
  outline: none;
}

.all_invoice {
  color: #000;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 2px;
  border: none;
  padding: 8px 20px;
  font-family: gsb;
  text-decoration: none;
  cursor: pointer;
}

.all_invoice a:hover {
  text-decoration: none !important;
  color: #000 !important;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background: #fff;
  border: 1px solid #fcc216;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: pointer;
  height: 17px;
  outline: 0;
  width: 17px;
}

input[type="checkbox"]:after {
  background-color: #fcc216;
  color: #000;
  content: " ";
  height: 17px;
  width: 17px;
}

input[type="checkbox"]:checked:after {
  content: "\2714";
  padding: 0px 1px 0px 4px;
}

.usertitle {
  font-family: gm;
  font-size: 14px;
  line-height: 16px;
  color: #fcc200;
}

.backArrow {
  background: #f9f9f9;
  padding: 5px;
  cursor: pointer;
}

.numberedit {
  display: flex;
}

.numbereditinput {
  outline: none;
  border: none;
  width: 100%;
  font-family: "gm";
  font-size: 13px;
  line-height: 15px;
  color: #221e1f;
}

.savebtnedit {
  font-family: "gm";
  font-size: 15px;
  line-height: 14px;
  color: #fcc200;
  cursor: pointer;
  background: none;
}

.head_txt {
  border-bottom: 1px solid #f2f2f2;
  font-family: "gsb";
  font-size: 16px;
  line-height: 19px;
  padding-bottom: 14px;
  color: #000000;
  align-items: center;
}

.head_txt_margin {
  margin-bottom: 1em;
}

.head_txt p {
  margin-bottom: 0em;
}

.numer_91 {
  font-family: "Gm";
  font-size: 13px;
  line-height: 15px;
  color: #0e0e0e;
  background: #ffdb63;
  border-radius: 2px 0px 0px 2px;
  padding: 13px 10px;
}

.btn_add_user {
  background: linear-gradient(91.79deg, #ffe185 -0.46%, #fcc200 104.3%);
  border-radius: 3.47661px;
  border: none;
  padding: 14px 20px;
  font-family: "gm";
  text-decoration: none;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  cursor: pointer;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.plusnineone {
  font-family: "gm";
  font-size: 13px;
  line-height: 15px;
  color: #221e1f;
  padding: 9px;
  background: #fcc200;
}

.flotpagination {
  display: flex;
  justify-content: flex-end;
  /* float: right; */

  margin-top: 15px;
}

.pass1 {
  outline: none;
}

/* manage user end */
.admin_left_sidebar {
  background-color: #f3f3f3;
  height: 90vh;
  padding-left: 25px;
}

.admin_side_menu_dashboardbtn_unactive {
  background: #fff;
  cursor: pointer;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  color: #000;
  font-family: gb;
  font-size: 14px;
  text-decoration: none;
}

.admin_side_menu_dashboardbtn_unactive:hover {
  text-decoration: none;
  color: #000;
}

.admin_inner_table_div {
  margin-top: 20px;
  background-color: #f4f4f4;
  /* padding: 18px; */
  /* height: calc(100vh - 217px); */
  height: 100%;
  border-radius: 5px;
}

.admin_table_outer {
  background-color: #fff;
  padding: 0px 0px 25px 0px;
  border-radius: 20px;
  /* height: 70vh;
  overflow: scroll; */
}

.add_btn {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border: none;
  padding: 8px 15px;
  cursor: pointer;
  font-family: gsb;
  border-radius: 5px;
  color: #000;
}

.labour_filter_btn {
  background: #f8f8f8;
  border: none;
  padding: 8px 15px;
  cursor: pointer;
  font-family: gsb;
  border-radius: 5px;
  color: #000;
}

.generate_link_btn {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border: none;
  padding: 8px 15px;
  cursor: pointer;
  font-family: gsb;
  border-radius: 5px;
  color: #000;
  width: 100%;
}

.delete_btn {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border: none;
  padding: 8px 15px 7px 20px;
  cursor: pointer;
  font-family: gsb;
  color: #000;
  border-radius: 5px;
}

.delete_btn2 {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border: none;
  padding: 8px 15px;
  cursor: pointer;
  font-family: gsb;
  color: #000;
  border-radius: 5px;
}

.edit_button {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border: none;
  padding: 7px !important;
  cursor: pointer;
  font-family: gm;
  border-radius: 5px;
}

.add_icon {
  color: #000;
  font-size: 20px !important;
}

.delete_icon {
  color: #000;
}

.crud_button {
  gap: 15px;
}

.add_project {
  color: #000;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 5px;
  border: none;
  padding: 10px 42px;
  font-family: gsb;
  text-decoration: none;
  cursor: pointer;
}

.add_project a:hover {
  text-decoration: none !important;
  color: #000;
}

.select_project_stage {
  width: 100%;
  height: 40px;
  border: 1px solid #ced4da;
  background: #f8f8f8;
  font-family: gm;
  font-size: 14px;
  border-radius: 0.25rem;
}

.select_project_stage:focus {
  outline: none;
}

.addproject_input {
  height: 40px;
  font-family: gm;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.addproject_input:focus {
  outline: none;
}

.addpro_heading {
  font-family: gb;
}

.cendrol_plus_package {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 5px;
  border: none;
  color: #000;
  padding: 8px;
  width: 70%;
  font-size: 14px;
}

.premium_package {
  background: linear-gradient(
    108.2deg,
    #da9917 -14.5%,
    #ffff90 66.64%,
    #ecbf26 109.69%
  );
  border-radius: 5px;
  border: none;
  color: #000;
  padding: 8px;
  width: 70%;
  font-size: 14px;
}

.admin_project_details_input {
  font-family: gm;
  padding: 5px;
  width: 90%;
  height: 40px;
  background: #fff;
  border: 1px solid #ced4da;
}

.admin_projectdet_select {
  width: 80%;
  height: 35px;
  border: 1px solid #ced4da;
  background: #f8f8f8;
  font-family: gm;
  font-size: 14px;
  border-radius: 0.25rem;
}

.admin_project_edit {
  background: #f3f3f3;
  height: calc(90vh - 94px);
  padding: 20px;
  margin-top: 10px;
  border-radius: 10px;
}

.admin_user_details_input {
  padding: 5px;
  background: #fff;
  border: 1px solid #ced4da;
  width: 100%;
  font-family: gm;
}

.admin_userdetails_select {
  width: 100%;
  height: 32px;
  border: 1px solid #ced4da;
  background: #fff;
  font-family: gm;
  font-size: 14px;
  border-radius: 0.25rem;
}

.del_button {
  position: absolute;
  right: 8%;
  /* top: 21.5%; */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.pass1:focus {
  outline: none;
}

.materialcatlog_right_part {
  background: #f8f8f8;
  border-radius: 8px;
}

.select_category_materialcatlog {
  background: #f8f8f8;
  border-radius: 2px;
  font-size: 12px;
  padding: 14px;
  font-family: gm;
  color: #000000;
  border: none;
  cursor: pointer;
}

.select_category_materialcatlog:focus {
  outline: none;
}

.btn_add_vendor_materialcatlog {
  background: #ffffff;
  border-radius: 5px;
  font-family: gm;
  font-size: 16px;
  color: #000000;
}

.materialdetails_txt_materialcatlog {
  font-family: gb;
  font-size: 26px;
  align-items: center;
  color: #000000;
}

.btn_edit_materialcatlog {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 2.66331px;
  font-family: gm;
  font-size: 16px;
  align-items: center;
  color: #000000;
}

.table_material_catalog {
  margin-bottom: 0px !important;
}

.table_material_catalog th {
  font-family: gsb;
  font-size: 13px;
  text-align: center;
  color: #ffffff;
  background: #221e1f;
  border-radius: 2.66331px 2.66331px 0px 0px;
  text-align: center;
}

.table_material_catalog td {
  text-align: center;
  font-family: gsb;
  font-size: 12px;
  text-align: center;
  color: #000000;
}

.table_material_catalog_noalign {
  text-align: left !important;
}

.delete_icon_table {
  color: #fe3e3e;
  cursor: pointer;
}

.btn_add_new_material_border {
  border: 1.33165px dashed #969696;
  cursor: pointer;
}

.btn_add_new_icon {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 2.66331px;
  color: #ffffff;
}

.btn_add_new_material_catalog {
  font-family: Msb;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  border: none;
  background: none;
}

.btn_view_details {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 5px;
}

/* Add Vendor details Pop Up */
.vendor_details_txt {
  font-family: gb;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #221e1f;
}

.btn_popup_close {
  /* background: #f8f8f8;
  border-radius: 100%; */
  padding: 0px;
  font-size: 20px;
}

.btn_personal_details,
.btn_personal_details_active {
  background: #eeeeee;
  border-radius: 2.66331px;
  font-family: gm;
  font-size: 14px;
  align-items: center;
  color: #000000;
}

.btn_personal_details_active {
  background: linear-gradient(
    162.29deg,
    #ffe185 -6.85%,
    #f7ba00 100.08%
  ) !important;
}

.label_txt_mc {
  padding-bottom: 10px;
  font-family: gm;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #909090;
}

.input_txt_mc {
  background: #f8f8f8;
  box-sizing: border-box;
  border-radius: 5px;
  border: none;
  width: 100%;
  font-family: Gm;
  font-size: 15px;
  /* display: flex; */
  /* box-shadow: 0px 0px 5px 1px rgba(250, 187, 24, 0.2); */
  align-items: center;
  color: #0e0e0e;
}

.input_txt_mc:focus {
  /* border: 1px solid #000 !important; */
  font-size: 16px;
  outline: none;
  color: #221e1f;
}

.input_txt_mc1 {
  background: #f8f8f8;
  box-sizing: border-box;
  border-radius: 5px;
  /* border: 0.5px solid #969696; */
  width: 100%;
  font-family: Gm;
  font-size: 15px;
  /* display: flex; */
  /* box-shadow: 0px 0px 5px 1px rgba(250, 187, 24, 0.2); */
  align-items: center;
  color: #0e0e0e;
}

.input_txt_mc3 {
  background: #f8f8f8;
  box-sizing: border-box;
  border-radius: 5px;
  /* border: 1px solid #e5e5ea; */
  width: 100%;
  font-family: Gm;
  font-size: 15px;
  /* display: flex; */
  /* box-shadow: 0px 0px 5px 1px rgba(250, 187, 24, 0.2); */
  align-items: center;
  color: #0e0e0e;
}

.input_txt_mc1:focus {
  /* border: 1px solid #fcc314 !important; */
  font-size: 16px;
  color: #221e1f;
}

.input_txt_mc:focus {
  /* border: 1px solid #fcc314 !important; */
  font-size: 16px;
  color: #221e1f;
}

.input_view_txt_mc {
  background: #f8f8f8;
  box-sizing: border-box;
  border-radius: 5px;
  border: none;
  width: 100%;
  font-family: gsb;
  align-items: center;
  font-size: 16px;
  color: #221e1f;
}

.input_txt_address {
  width: 100%;
  background: #fff;
  border-radius: 5px;
  font-family: gsb;
  font-size: 16px;
  border: 0.5px solid #969696;
  color: #221e1f;
}

.btn_save_continue_mc {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 5px;
  font-family: gm;
  display: flex;
  padding: 14px 25px;
  justify-content: center;
  font-size: 15px;
  align-items: center;
  border: none;
  color: #000000;
}

.delete_vendor_btn {
  border-radius: 5px;
  font-family: gm;
  font-size: 14px;
  align-items: center;
  color: #fe3e3e;
  background: #fff2f2;
  border-radius: 1px;
  display: flex;
  align-items: center;
  border: none;
  padding: 10px 14px;
}

.delete_vendor_btn:hover {
  /* color: #fff; */
}

.btn_del_continue_mc {
  background: red;
  border-radius: 5px;
  font-family: gm;
  font-size: 18px;
  align-items: center;
  border: none;
  color: #000000;
}

.view_heading_txt {
  font-family: gm;
  font-size: 14px;
  color: #221e1f;
}

.view_value_txt {
  background: #f8f8f8;
  border-radius: 5px;
  font-family: gsb;
  font-size: 16px;
  align-items: center;
  color: #221e1f;
}

.add_vendor_form_top_stripe {
  background: #000;
  color: #fff;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 15px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.vendor_details_edit {
  border-radius: 5px;
  font-family: gm;
  padding: 4px;
  width: 100%;
  background: #f3f3f3;
  border: 1px solid #ced4da;
}

.vendor_details_edit:focus {
  outline: none;
}

.add_bulk_material {
  color: #000;
  padding: 18px;
  cursor: pointer;
  border: none;
  width: 100%;
  text-align: center;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 5px;
  font-family: Msb;
}

.dropzone {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  border: 3px dashed #969696;
  background: #fffcf3;
  color: #bdbdbd;
  margin-bottom: 20px;
  height: 60vh;
  display: flex;
  align-items: center;
}

.vendor_bulk_upload_text {
  font-family: gsb;
  color: #000;
}

.browse_bulk_vendor {
  color: #000;
  padding: 12px;
  font-family: gsb;
  border: 1px solid #fcc216;
  box-sizing: border-box;
  border-radius: 3px;
  background: #fffcf3;
}

.upload_vendor_bulk {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  font-family: gm;
  font-size: 16px;
  color: #000000;
  border: none;
  cursor: pointer;
}

.upload_vendor_bulk:focus {
  outline: none;
}

.parentDiv {
  padding-top: 50px;
  /* background-color: aquamarine; */
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.line {
  height: 3px;
  display: flex;
  width: 80%;
  margin-left: 5%;
  /* Temp property*/
  /* background-image: linear-gradient(90deg, green 50%, rgba(128, 128, 128, 0.472) 50%); */
  background-color: rgba(128, 128, 128, 0.472);
  justify-content: space-between;
  align-items: center;
}

.fifty {
  background-image: linear-gradient(
    90deg,
    green 50%,
    rgba(128, 128, 128, 0.472) 50%
  );
}

.hundred {
  background-color: green;
}

.dashboard_stats .statss {
  background: #f9f9f9;
  border-radius: 6px;
  padding: 14px;
}

.box_elements {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 4px;
  padding: 10px;
}

.box_elements img {
  width: 40px;
}

.statss .gaps {
  gap: 10px;
}

.stats_vaul span {
  font-family: gsb;
  font-size: 19px;
  color: #000;
}

.stats_vaul p {
  font-family: gm;
  font-family: 16px;
  color: #000;
}

/* Requests screen */
.add_req_form_fields {
  background: #f8f8f8;

  border: 0.5px solid #969696;
  padding: 5px;
  border-radius: 5px;
}

.filter_head {
  font-family: gr;
}

.plus_minus {
  border-top: 0.5px solid #969696;
  border-right: 0.5px solid #969696;
  border-left: 0.5px solid #969696;
  border-bottom: 0.5px solid #969696;
}

.mat_num {
  background: rgb(248, 248, 248);
  padding: 7px;
  border-left: 0.5px solid #969696;
  border-top: 0.5px solid #969696;
  border-bottom: 0.5px solid #969696;
  /* border-right: 0.5px solid #969696; */
}

.req_details_left {
  background-color: #f3f3f3;
  padding: 30px;
}

.parent-request {
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.heading_req {
  font-size: 24px;
  color: #000;
  font-family: gb;
  font-weight: 700;
}

tr:hover {
  color: #221e1f;
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
}

tr:hover .partner_back_btn {
  color: #fcc216;
}

.navbars {
  /* margin: 5px; */
  /* width: 100%; */
}

.left_sidebar {
  background-color: #f5f5f5;
  height: 100vh;
  padding-left: 25px;
}

.req_right_div {
  background: #fff;
  border-radius: 5px;
  padding: 5px;
}

.inner_table_for_material {
  background-color: #f5f5f5;
  /* padding: 15px; */
  border-radius: 5px;
  margin-top: 2rem;
  height: calc(100vh - 250px);
  overflow-y: scroll;
}

.inner_table_div {
  background-color: #f5f5f5;
  height: calc(90vh - 200px);
  overflow-y: scroll;
  border-radius: 5px;
}

.notFoundImage {
  width: 25%;
  height: auto;
}

.homeBtn {
  padding: 15px 45px;
  border: none;
  margin-top: 20px;
  font-style: normal;
  border-radius: 2px;
  background-image: linear-gradient(to right, #ffe082, #fcc50d);
  cursor: pointer;
}

.msgTxt {
  color: #8c8c8c;
  align-items: center;
  text-align: center;
  font-size: 16px;
  max-width: 35vw;
}

.headerTxt {
  font-size: 36px;
  margin: 10px, 0px;
}

.partnetDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 90vh;
}

.inner_table_div_forrequest {
  background-color: #f5f5f5;
  height: 55vh;
  overflow: scroll;
  border-radius: 5px;
}

.all_req_table_heading {
  font-family: gb;
  color: #000;
  /* margin-left: 8px; */
}

.more_btn {
  width: 30px;
}

.active_dot {
  width: 10px;
  height: 10px;
  background-color: #fe0c0c;
  border-radius: 50%;
  margin-right: 4px;
}

.med_pri {
  width: 10px;
  height: 10px;
  background-color: #ff7410;
  border-radius: 50%;
  margin-right: 4px;
}

.low_pri {
  width: 10px;
  height: 10px;
  background-color: #fcc314;
  border-radius: 50%;
  margin-right: 4px;
}

/* Side navbar */
.side_menu_dashboardbtn_unactive {
  /* background: #f4f4f4; */
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  color: #000;
  font-family: gm;
  font-size: 13px;
  text-decoration: none;
}

.side_menu_dashboardbtn_unactive:hover {
  text-decoration: none !important;
  color: #000;
}

.side_nav_icons_active {
  color: #000;
}

.side_menu_dashboardbtn {
  padding-left: 20px;
  /* background: #f4f4f4; */
  cursor: pointer;
  /* justify-content: space-evenly; */
  display: flex;
  align-items: center;
  /* padding: 15px; */
  border-radius: 5px;
  color: #000;
  font-family: gm;
  font-size: 14px;
  text-decoration: none;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
}

.side_menu_dashboardbtn:hover .side_nav_icons {
  color: #000;
  text-decoration: none;
}

.side_menu_dashboardbtn:hover {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  color: #000;
  text-decoration: none;
}

.unactive_tabs_nav {
  padding-left: 20px;
  font-family: gb;
  font-size: 14px;
}

.side_nav_icons {
  color: #f7ba00;
}

.add_req_btn {
  padding: 5px 10px;
  height: 35px;
  background: linear-gradient(162.29deg, #ffe185 14.78%, #f7ba00 95.75%);
  border: navajowhite;
  font-family: "gsb";
  border-radius: 5px;
  color: #000;
}

/* Request Details */
.carousel_btns {
  border-radius: 50%;
  padding: 5px;
}

.mat_recieved {
  background: #3add5e;
  cursor: pointer;
  border: none;
  color: #fff;
  text-align: center;
  padding: 10px 30px;
  font-family: gsb;
}

.all_request_below_btns {
  gap: 10px;
}

.land_card_profile {
  height: inherit !important;
}

.approved_flag {
  font-family: gm;
  margin-left: 10px;
  text-align: center;
  padding: 5px 10px;
  background: #3add5e;
  border-radius: 8px;
  color: #fff;
}

.rejected_flag {
  margin-left: 10px;
  font-family: gm;
  text-align: center;
  padding: 5px 10px;
  background: #fe3e3e;
  border-radius: 8px;
  color: #fff;
}

.separator_line {
  /* border: 2px dashed #f3f3f3; */
  margin-top: 25px;
  margin-bottom: 20px;
}

.reject_btn {
  background: #f4f4f4;
  border: 1.8px solid #fe3e3e;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: gsb;
  padding: 6px 18px;
  color: #f33e3e;
  font-size: 12px;
}

.back_arrow {
  color: #000;
}

.material_details_table {
  font-family: gsb;
  color: #969696;
  font-size: 13px;
  margin-bottom: 10px;
}

.material_details_tabledata {
  font-family: gsb;
  color: #000;
  font-size: 15px;
  margin-bottom: 10px;
}

.material_details {
  font-family: gsb;
  color: #000;
}

.req_details_main_sec {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  /* height: 620px;
  overflow-y: scroll; */
}

.req_details_main_sec_v {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
}

.req_details_main_sec2 {
  background-color: #f3f3f3;
  padding: 20px;
  border-radius: 5px;
}

.req_details_main_sec1 {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
}

.approve_btns {
  background: #3add5e;
  font-family: Gm;
  /* letter-spacing: 1px; */
  border-radius: 2px;
  color: #fff;
  border: none;
  width: 100%;
  padding: 10px;
  cursor: pointer;
}

.material_details_div {
  padding: 20px;
  background: #ffffff;
  /* box-shadow: 0px 18px 33px -9px rgba(0, 0, 0, 0.11); */
  border-radius: 5px;
}

.reject_btns {
  /* border: 1px solid #fe3e3e; */
  box-sizing: border-box;
  border-radius: 2px;
  color: #0e0e0e;
  font-family: gm;
  /* letter-spacing: 1px; */
  width: 100%;
  padding: 10px;
  background: #f9f9f9;
}

.details span {
  color: #969696;
  font-size: 13px;
  font-family: gm;
}

.details p {
  color: #000;
  font-weight: 600;
  font-family: gsb;
  font-size: 14px;
}

.high_priority {
  background-color: #fe3e3e;
  border-radius: 4px;
  color: #fff;
  border: none;
  padding: 8px 12px;
  font-family: gm;
  cursor: unset !important;
  font-weight: 300;
}

.med_priority {
  cursor: none;
  border: none;
  background: #ff7410;
  border-radius: 4px;
  color: #fff;
  /* border: 1px solid #ff7410; */
  padding: 8px 12px;
  cursor: unset !important;
  font-family: gm;
  font-weight: 300;
}

.low_priority {
  background-color: #fcc314;
  border-radius: 4px;
  color: #fff;
  border: none;
  cursor: unset !important;
  padding: 8px 12px;
  font-family: gm;
  font-weight: 300;
}

.separtor {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 3px dashed #e5e5e5;
}

.create_purchase_order_btn {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 5px;
  font-size: 13px;
  justify-content: center;
  border: none;
  color: #000;
  cursor: pointer;
  padding: 10px 18px;
  width: 230px;
  font-family: gsb;
  cursor: pointer;
}

.cancel_purchase_order_btn {
  background: #fff;
  color: red;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 10px 18px;
  font-family: gsb;
  font-size: 13px;
}

.req_details_close_order {
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 5px;
  padding: 12px 18px;
  font-family: gsb;
  width: 50%;
  cursor: pointer;
}

.close_poorder {
  background: linear-gradient(180deg, #ff3535 -10.87%, #af1818 111.12%);
  border-radius: 5px;
  border: none;
  padding: 12px 25px;
  color: #fff;
  font-family: gm;
  width: 50%;
  cursor: pointer;
}

.text_area {
  font-family: Gm;
  background-color: #f4f4f4;
  padding: 10px;
  border: 1px solid #969696;
  border-radius: 5px;
  width: 100%;
}

.text_area:focus {
  outline: none;
}

.reject_popup_button {
  background: linear-gradient(180deg, #ff3535 -10.87%, #af1818 111.12%);
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  color: #fff;
  border: none;
  padding: 12px;
  width: 100%;
  font-family: gsb;
}

/* Purchase Order */
.po_heading {
  width: 35%;
  background: #000;
  text-align: center;
  align-self: center;
}

@media print {
  .po_heading {
    width: 39%;
    background: #000;
    text-align: center;
    align-self: center;
  }
}

.copy_po_select_dropdown {
  background: #ffffff;
  border: 0.5px solid #969696;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
}

.copy_po_select_dropdown:focus {
  outline: "none";
}

.copy_po_select_dropdown:visited {
  outline: "none";
}

.mate_save_btn {
  background: #f6ba00;
  border: none;
  padding: 5px 15px;
  font-size: 12px;
  font-family: "gsb";
}

a:hover {
  text-decoration: none !important;
  color: #000 !important;
}

.pre_values {
  background: #ffff;
  width: 100%;
  padding: 10px;
  font-family: gsb;
}

.vendor_address {
  background: #fff;
  border: none;
  border-radius: 5px;
  font-family: gm;
  padding: 5px;
  font-size: 12px;
}

.vendor_address:focus {
  outline: none;
}

.savenview_icon {
  font-size: 18px;
}

.purchase_order_input {
  background-color: #fff;
  padding: 12px;
  font-size: 12px;
  border: none;
  font-family: gsb;
  width: 100%;
}

.purchase_order_input:focus {
  outline: none;
}

.address_sections {
  padding: 15px;
  background: #f4f4f4;
  border-radius: 5px;
}

.add_new_term {
  color: #fcc314;
  font-family: gsb;
  cursor: pointer;
}

.payment_adv_details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  background-color: #f4f4f4;
}

.delete_button {
  border: none;
  padding: 15px;
  color: #fe3e3e;
}

.add_material_div {
  display: flex;
  justify-content: center;
  padding: 10px;
  border: 2px dashed gray;
  font-family: Msb;
  cursor: pointer;
  /* background-color: #f4f4f4; */
}

.add_mat_icon {
  color: #f6ba00;
  font-family: Msb;
}

.extra_charges {
  font-family: gr;
  font-size: 14px;
  /* margin-left: 10px; */
  color: #000;
  font-weight: 600;
}

.package_btn {
  background: linear-gradient(
    108.2deg,
    #da9917 -14.5%,
    #ffff90 66.64%,
    #ecbf26 109.69%
  );
  border-radius: 5px;
  border: none;
  color: #000;
  padding: 8px;
  width: 60%;
  font-family: gr;
  font-weight: 600;
}

.package_btn2 {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 5px;
  border: none;
  color: #000;
  padding: 8px;
  width: 60%;
  font-family: gr;
  font-weight: 600;
}

/* Project Details */
.all_proj_separation {
  margin-top: 20px;
}

.pro_second_sec {
  display: flex;
  justify-content: space-between;
}

.all_issues_btn {
  cursor: pointer;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 5px;
  border: none;
  font-size: 12px;
  font-weight: 600;
  font-family: gr;
  padding: 8px 20px;
  color: #000;
}

.partner_back_btn {
  color: #969696;
  font-size: 20px;
}

.btn-close {
  font-size: 10px !important;
}

.no_records_found {
  color: #ff3535;
  font-size: 30px !important;
}

/* All invoice */
.eye_icon {
  font-size: 20px;
  margin-left: 5px;
}

.red_alert {
  color: #dc3545;
  width: 2em !important;
  height: 2em !important;
}

.error {
  color: #dc3545 !important;
  font-family: gr !important;
  font-size: 12px !important;
  font-weight: 700;
}

.errorq {
  margin-top: 30px;
  color: #dc3545 !important;
  font-family: gr !important;
  font-size: 12px !important;
  font-weight: 700;
}

.invoice_view_btn {
  padding: 10px;
  background: #f9f9f9;
  border: 0.5px solid #d1d1d1;
  box-sizing: border-box;
  border-radius: 5px;
  color: #000;
  font-size: 15px;
}

.invoice_download_btn {
  padding: 10px;
  background: #f9f9f9;
  border: 0.5px solid #d1d1d1;
  box-sizing: border-box;
  border-radius: 5px;
}

.invoice_download_btn:hover {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
}

.invoice_view_btn:hover {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
}

.invoice_upload_form_input {
  background: #ffffff;
  border: 0.5px solid #969696;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Gm;
  width: 100%;
  padding: 5px;
}

.invoice_upload_form_input:focus {
  background: #f8f8f8;
}

.invoice_upload_form_label {
  font-family: gsb;
  color: #000;
}

/* Purchase order view  */
.purchaseorderview_main_sec {
  background: #ffffff;
  border: 1px solid #ececec;
  box-sizing: border-box;
  padding: 30px;
}

.top_stripe {
  font-family: Gsb;
  background: #e6e6e6;
  padding: 12px;
  color: #000;
}

.top_stripe_po {
  font-family: Gsb;
  background: #fde8b9;
  padding: 12px;
  color: #000;
}

.purchase_order_view_all_details {
  background: #ffff;
  line-height: 2rem;
  color: #000;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 16px;
  font-family: "gsb";
  font-size: 14px;
  border: 1px solid #d5d5d5;
}

.poview_table_content {
  font-family: gsb;
}

.termsandconditions {
  color: #969696;
  font-family: Gm;
}

.popup_warning_icon {
  color: #ff3535 !important;
  font-size: 50px !important;
}

.popup_icon_center,
.popup_text_center {
  flex-direction: row;
  text-align: center;
}

.popup_addvalid_cred {
  font-family: "Gsb";
  font-style: normal;
  font-size: 20px;
  text-align: center;
  color: #000000;
}

.popupinvalid_red_ok {
  color: #ffffff;
  background: linear-gradient(180deg, #ff3535 -10.87%, #af1818 111.12%);
  border-radius: 5px;
  font-family: "Gsb";
  width: 80% !important;
  padding: 10px;
  border: none;
}

/* project details */
.admin_lead_package_btn2 {
  background: #f7e875;
  border-radius: 2px;
  border: none;
  color: #000;
  padding: 8px;
  width: 85%;
  font-size: 14px;
}

.admin_lead_package_btn1 {
  background: #f0f0f0;
  border-radius: 2px;
  border: none;
  color: #000;
  padding: 8px;
  width: 85%;
  font-size: 14px;
}

@media print {
  ::-webkit-scrollbar {
    display: none;
  }

  body {
    -webkit-print-color-adjust: exact !important;
  }

  .partner_back_btn {
    visibility: hidden;
  }

  .create_purchase_order_btn {
    visibility: hidden;
  }

  .req_details_close_order {
    visibility: hidden;
  }

  .left_sidebar {
    visibility: hidden;
  }

  .navbars {
    visibility: hidden;
  }

  .top_stripe {
    background-color: #e6e6e6 !important;
    padding: 12px;
    color: #000;
  }
}

.accordion-button::after {
  /* background-image: url("./Assets/Group\ 488812.png") !important; */
  transform: scale(4.5) !important;
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  background-position: center;
  content: "";
  margin-right: 40px !important;
  background-repeat: no-repeat;
  background-size: 1.25rem;
}

.close_btn_popup_edit {
  position: absolute;
  right: 0;
  padding: 5px;
}

.save_btn {
  width: 20%;
  padding: 5px;
  background: linear-gradient(162.29deg, #ffe185 14.78%, #f7ba00 95.75%);
  border: navajowhite;
  padding: 5px 20px;
  font-family: "gm";
}

.popup-overlay-content {
  margin: auto;
  width: 84% !important;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.popup-overlay1-content {
  margin: auto;
  width: 45% !important;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.popup-overlay3-content {
  margin: auto;
  width: 60% !important;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.popup-overlay2-content {
  margin: auto;
  width: 30% !important;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.popup-content2 {
  margin: auto;
  background: #fff;
  width: 45% !important;
  padding: 0px !important;
  border: 1px solid #d7d7d7;
}

.popup-overlay2-content {
  margin: auto;
  width: 35% !important;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

/*  */
.popup-overlaypo-content {
  margin: auto;
  width: 30% !important;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.popup-contentpo {
  margin: auto;
  background: #fff;
  width: 45% !important;
  padding: 0px !important;
  border: 1px solid #d7d7d7;
}

.popup-overlaypo-content {
  margin: auto;
  width: 25% !important;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

/*  */
.popup-content1 {
  margin: auto;
  background: #fff;
  width: 45% !important;
  padding: 0px !important;
  border: 1px solid #d7d7d7;
}

.popup-overlay3-content {
  margin: auto;
  width: 58% !important;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.popup-content3 {
  margin: auto;
  background: #fff;
  width: 45% !important;
  padding: 0px !important;
  border: 1px solid #d7d7d7;
}

.modal1 > .header1 {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}

.modal1 > .content1 {
  width: 100%;
  padding: 10px 5px;
}

.modal1 > .actions {
  margin: auto;
}

.modal1 > .actions {
  width: 100%;
  padding: 10px 5px;
  text-align: center;
}

.close {
  color: red;
}

/* @media (min-width: 1200px) { */
.modal1 {
  max-width: 1140px;
}

.poup_scroll_imp {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.version_details {
  position: absolute;
  bottom: 0;
  font-family: gsb !important;
  padding: 10px;
}

.link_icon {
  color: #fcc314;
  transform: rotate(-30deg);
}

.rfq_quotations_main {
  padding: 25px;
  border: 1px solid #d5d5d5;
}

.view_quotation_btn {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 5px;
  font-size: 13px;
  justify-content: center;
  border: none;
  color: #000;
  padding: 10px 18px;
  width: 150px;
  font-family: gsb;
  cursor: pointer;
}

.cendrol_quot_logo_div {
  background: rgb(249, 249, 249);
  border-radius: 5px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  padding: 6px;
  align-items: center;
}

.rfq_details_status_div {
  background: #f9f9f9;
  border-radius: 10px;
}

.sub_quot_heading {
  font-family: "gm";
  color: #969696;
  font-size: 12px;
}

.rfq_stat_open {
  background: linear-gradient(94.86deg, #64f985 -3.38%, #2aa946 111.69%);
  /* background: linear-gradient(99.83deg, #ff3535 0%, #af1818 124.79%); */
  border-radius: 5px;
  border: none;
  color: #fff;
  padding: 10px;
  width: 100px;
}

.rfq_stat_closed {
  /* background: linear-gradient(94.86deg, #64F985 -3.38%, #2AA946 111.69%); */
  background: linear-gradient(99.83deg, #ff3535 0%, #af1818 124.79%);
  border-radius: 5px;
  border: none;
  color: #fff;
  padding: 10px;
  width: 100px;
}

.rfq_share {
  background: #f8f8f8;
  cursor: pointer;
  padding: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quo_due_date {
  background: #f8f8f8;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #e5e5ea;
  width: 100%;
  font-family: gsb;
  padding: 8px;
  font-size: 15px;
  display: flex;
  box-shadow: 0px 0px 5px 1px rgb(250 187 24 / 20%);
  align-items: center;
  color: #969696;
}

.generated_link {
  font-family: gm;
}

.rfq_vendor_listing {
  text-decoration: underline;
  color: #000;
  font-family: gm;
}

.rfqshare_icon {
  font-size: 20px !important;
}

.rfqshare_btn {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border: none;
  padding: 4px 15px;
  cursor: pointer;
  font-family: gm;
  border-radius: 5px;
}

.link_sharing_input {
  background: #f8f8f8;
  border-radius: 3.75px;
  border: none;
  width: 100%;
  padding: 15px;
}

.link_sharing_input:focus {
  outline: none;
}

.paste_link_cancel_btn {
  padding: 10px;
  width: 95%;
  border: none;
  font-family: gsb;
  border-radius: 5px;
}

.send_link_btn {
  width: 95%;
  font-family: gsb;
  border: none;
  padding: 10px;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 5px;
}

.copy_purchase_order {
  border: 1px solid #000;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 20px;
  font-family: gsb;
  text-decoration: none;
  cursor: pointer;
}

.ticket_circle_left {
  background: #fff;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: absolute;
  bottom: 12rem;
  left: 15.4rem;
  display: none;
}

.ticket_circle_right {
  background: #fff;
  display: none;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: absolute;
  right: 1rem;
  bottom: 12rem;
}

/* Report Screen */
.report_drop li {
  font-size: 13px;
  font-family: gm;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: unset;
}

.reports_filter_btns {
  font-family: gm;
  background: #f8f8f8;
  border-radius: 5px;
  padding: 10px;
  border: "none";
  color: #000;
  cursor: pointer;
}

.reports_filter_btns:hover {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
}

.filter_icons {
  width: 20px !important;
}

#filter_range {
  background-color: #fff;
}

.filter_usertype {
  border-radius: 0px;
  padding: 13px 16px 13px 11px !important;
  background: #f8f8f8 !important;
  text-align: start !important;
  font-family: gm !important;
  width: 100%;
  border: 1px solid #e5e5ea;
  color: #8e8e93;
}

.filter_usertype:focus {
  outline: none;
}

.filter_op_btns {
  cursor: pointer;
  padding: 14px;
  width: 100%;
  font-family: "gm";
  color: #000;
  border-radius: 5px;
  background-color: #f8f8f8 !important;
}

.filter_op_btns:hover {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
}

.project_rep_stats1 {
  background: #f8fffa;
  border: 1px solid #e4ffea;
  border-radius: 5px;
}

.project_rep_stats1 .comment {
  align-self: center;
  padding-left: 20px;
}

.project_rep_stats1 .comment span {
  color: #3add5e;
  font-family: gsb;
}

.project_rep_stats1 .stats_value {
  background: #e4ffea;
  padding: 10px 20px;
  color: #000;
  font-weight: 700;
}

.project_rep_stats2 {
  background: #fffefa;
  border: 1px solid #fff4e2;
  border-radius: 6px;
}

.project_rep_stats2 .comment {
  align-self: center;
  padding-left: 20px;
}

.project_rep_stats2 .stats_value {
  padding: 10px 20px;
  background: #fff4e2;
  color: #000;
  font-weight: 700;
}

.project_rep_stats2 .comment span {
  color: #fdbd53;
  font-family: gsb;
}

.project_rep_stats3 {
  background: #fcffff;
  border: 1px solid #edffff;
  border-radius: 6px;
}

.project_rep_stats3 .comment {
  align-self: center;
  padding-left: 20px;
}

.project_rep_stats3 .stats_value {
  padding: 10px 20px;
  color: #000;
  font-weight: 700;
  background: #edffff;
}

.project_rep_stats3 .comment span {
  color: #24c1cc;
  font-family: gsb;
}

.project_status_report_heading {
  font-family: gsb;
  color: #000;
}

.percentage_val {
  font-family: gm;
  color: #000;
  font-size: 12px;
}

.inner_project_status_div {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 5px;
}

.mat_used {
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
}

.material_used_inner {
  padding: 18px;
  background: #f9f9f9;
  border-radius: 5px;
}

.card_head {
  color: #000;
  font-family: gsb;
}

.report_card_head {
  color: #969696;
  /* text-transform: uppercase; */
  font-family: "gm";
}

.report_card_value {
  color: #000;
  font-family: "gsb";
}

.total_records_text {
  color: #000;
  font-family: gsb;
  font-size: 14px;
}

/* -------++++++++++++++-- Next Release Css changes -------++++++++++++++--
--------------++++++++++-----------------------------------+++++++++++++++--
*/
.top_request_btns {
  gap: 10px;
}

.all_request_btn {
  background: #f9f9f9;
  border-radius: 2px;
  padding: 8px;
  color: #000;
  font-family: Gm;
  cursor: pointer;
  border-radius: 5px;
}

.all_request_btn_active {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
}

.all_request_btn:hover {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.content1 {
  display: none;
}

.active-content1 {
  display: block;
}

.request_details_material_table_strip {
  background: #e8e8e8;
  color: #0e0e0e;
  padding: 17px 21px;
  height: 49px;
  font-family: "gsb";
  border-radius: 6px 6px 0px 0px;
}

.download_quotation_btn {
  width: 100%;
  /* border: 0.5px solid #dbdbdb; */
  border-radius: 5px;
}

.show_invoice_btn {
  background: linear-gradient(99.98deg, #2e2e2e 4.09%, #0e0e0e 100.77%);
  border-radius: 5px;
  font-family: Gm;
  padding: 10px 20px;
  color: #fff;
}

.invoice_line_style {
  border-left: 2px dashed #dbdbdb;
}

/* step progress bar css */
.wrapper {
  width: 330px;
  font-family: "Gm" !important;
  font-size: 14px;
  color: #000;
}

.StepProgress {
  position: relative;
  padding-left: 45px;
  list-style: none;
}

.StepProgress::before {
  display: inline-block;
  content: "";
  position: absolute;
  top: 0;
  left: 19px;
  width: 10px;
  height: 100%;
  border-left: 2px solid #ccc;
}

.StepProgress-item {
  position: relative;
  counter-increment: list;
}

.StepProgress-item:not(:last-child) {
  padding-bottom: 29px;
}

.StepProgress-item::before {
  display: inline-block;
  content: "";
  position: absolute;
  left: -26px;
  height: 100%;
  width: 10px;
}

.StepProgress-item::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: -37px;
  width: 23px;
  height: 23px;
  border: 2px solid #ccc;
  border-radius: 0%;
  background-color: #fff;
}

.StepProgress-item.is-done::before {
  border-left: 2px solid #fcc216;
}

.StepProgress-item.is-done::after {
  content: "✔";
  font-size: 13px;
  color: #fff;
  text-align: center;
  border: 2px solid #fcc216;
  background-color: #fcc216;
  border-radius: 3px;
}

.StepProgress-item.current::before {
  border-left: 2px solid green;
}

.StepProgress-item.current::after {
  content: counter(list);
  padding-top: 1px;
  width: 19px;
  height: 18px;
  top: -4px;
  left: -40px;
  font-size: 14px;
  text-align: center;
  color: green;
  border: 2px solid green;
  background-color: white;
}

.StepProgress strong {
  display: block;
}

.wrapper1 {
  width: 270px;
  font-family: "Gm";
  font-size: 14px;
}

.StepProgress1 {
  position: relative;
  padding-left: 45px;
  list-style: none;
}

.StepProgress1::before {
  display: inline-block;
  content: "";
  position: absolute;
  top: 0;
  left: 19px;
  width: 10px;
  height: 100%;
  border-left: 2px solid #fff;
}

.StepProgress-item1 {
  position: relative;
  counter-increment: list;
}

.StepProgress-item1:not(:last-child) {
  padding-bottom: 29px;
}

.StepProgress-item1::before {
  display: inline-block;
  content: "";
  position: absolute;
  left: -26px;
  height: 100%;
  width: 10px;
  /* display: none; */
}

.StepProgress-item1::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: -37px;
  width: 23px;
  height: 23px;
  border: 2px solid #fff;
  border-radius: 0%;
  background-color: #fff;
}

.StepProgress-item1.is-done::before {
  border-left: 2px solid #fff;
}

.StepProgress-item1.is-done::after {
  content: "✔";
  font-size: 13px;
  color: #fff;
  text-align: center;
  border: 2px solid #fff;
  background-color: #fff;
}

.StepProgress-item1.current::before {
  border-left: 2px solid #fff;
}

.StepProgress-item1.current::after {
  content: counter(list);
  padding-top: 1px;
  width: 19px;
  height: 18px;
  top: -4px;
  left: -40px;
  font-size: 14px;
  text-align: center;
  color: green;
  border: 2px solid green;
  background-color: white;
}

.StepProgress1 strong {
  display: block;
}

.material_request_lifecycle_date_time {
  font-family: Gm;
  color: #969696;
}

.new_request {
  font-family: gm;
  margin-left: 10px;
  text-align: center;
  padding: 5px 10px;
  background: #3eaefe;
  border-radius: 5px;
  color: #fff;
}

.po_iscreated {
  font-family: gm;
  margin-left: 10px;
  text-align: center;
  padding: 5px 10px;
  background: #fcc216;
  border-radius: 5px;
  color: #000;
}

.partially_received {
  font-family: gm;
  margin-left: 10px;
  text-align: center;
  padding: 5px 10px;
  background: #fb8c3c;
  border-radius: 5px;
  color: #fff;
}

.payment_done {
  font-family: gm;
  margin-left: 10px;
  text-align: center;
  padding: 5px 10px;
  background: #cc3efe;
  border-radius: 5px;
  color: #fff;
}

.request_closed {
  font-family: gm;
  margin-left: 10px;
  text-align: center;
  padding: 5px 10px;
  background: #0e0e0e;
  border-radius: 5px;
  color: #fff;
}

.received_no {
  color: #3add5e;
}

.green {
  color: #3add5e;
}

.red {
  color: #fe3e3e;
}

.Project_Documents {
  cursor: pointer;
  font-family: "gm";
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  background: #fff8e1;
  border-radius: 1px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 14px 16px 14px 16px;
  margin-top: 10px;
}

.filter_container {
  width: 34%;
  background-color: white;
  height: fit-content;
  /* margin-bottom: 11%; */
}

.filter_container_config {
  width: 25%;
  background-color: white;
  height: fit-content;
  /* margin-bottom: 11%; */
}

.Filter_text {
  font-family: gb;
  font-size: 18px;
  line-height: 21px;
  text-transform: capitalize;
  color: #0e0e0e;
}

.poFilter_text {
  color: var(--Black, #221e1f);
  font-family: Gm;
  font-size: 13px;
  font-weight: 300;
}

.poFilter_title {
  color: #000;
  font-family: Gm;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.poResetall_btn {
  width: 163px;
  height: 40px;
  border-radius: 2px;
  background: #f2f2f2;
  font-family: Gm;
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
  color: #000;
}

.poApply_btn {
  width: 163px;
  height: 40px;
  border-radius: 2px;
  background: linear-gradient(93.98deg, #ffe185 6.58%, #f7ba00 105.53%);
  font-family: Gm;
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
  color: #000;
}

.form-select:focus {
  outline: none !important;
  box-shadow: none !important;
}

.Package_title {
  font-family: gm !important;
  font-size: 13px !important;
  line-height: 14px;
  /* color: #221e1f; */
  padding-bottom: 4px;
}

.ResetAll {
  padding: 15px;
  background: #f8f8f8;
  border-radius: 1px;
  font-family: "gsb";
  font-size: 13px;
  line-height: 15px;
  color: #221e1f;
  text-align: center;
  cursor: pointer;
}

.Allfilter {
  background: linear-gradient(93.98deg, #ffe185 6.58%, #f7ba00 105.53%);
  border-radius: 1px;
  font-family: "gsb";
  font-size: 13px;
  line-height: 15px;
  color: #221e1f;
  padding: 15px;
  text-align: center;
  cursor: pointer;
}

.ConstructionStart {
  width: 100%;
  border: none;
  outline: none;
  background: #f8f8f8;
  border-radius: 2px;
  padding: 14px 9px 14px 9px;
}

.florG {
  background-color: #fcc200;
  font-family: gm;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  padding: 13px;
}

.addremovebutton {
  background: #fcc200;
  border-radius: 2px 0px 0px 2px;
  padding: 5px;
}

.countdisply {
  font-family: gm;
  font-size: 15px;
  line-height: 18px;
  background: white;
  border-radius: 2px 0px 0px 2px;
  padding: 8px 27px 8px 27px;
}

.uploaddoc_btn {
  background: linear-gradient(93.98deg, #ffe185 6.58%, #f7ba00 105.53%);
  border-radius: 2px;
  padding: 10px 20px;
  /* width: 13%; */
  font-family: "gm";
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #221e1f;
  cursor: pointer;
}

.fontdocument {
  cursor: pointer;
  font-family: gm;
  font-size: 12px;
  line-height: 14px;
  color: #221e1f;
}

.fontdocument_dim {
  cursor: pointer;
  color: #919191;
  font-family: gm;
  font-size: 15px;
  line-height: 14px;
}

.fontdocument_active {
  cursor: pointer;
  font-family: gm;
  font-size: 15px;
  line-height: 14px;
  color: #221e1f;
}

.uploaddoc_btn2,
.download_invoice {
  background: #ffffff;
  border-radius: 1px;
  padding: 9px 10px;
}

tr:hover .uploaddoc_btn2 {
  background: #f9f9f9;
}

tr:hover .download_invoice {
  background: linear-gradient(93.98deg, #ffe185 6.58%, #f7ba00 105.53%);
}

tr:hover .download_invoice svg path {
  fill: #000000;
}

.ant-pagination-total-text {
  font-family: "gm";
  font-size: 12px !important;
  color: #0e0e0e !important;
  text-align: right;
}

.ant-pagination-item,
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  font-family: "gm";
  color: #969696;
  background: #f2f2f2;
  border-radius: 2px;
  outline: none;
  border: none;
}

.ant-pagination-prev .ant-pagination-item-link:hover,
.ant-pagination-next .ant-pagination-item-link:hover {
  color: #969696 !important;
}

.ant-pagination-item:hover a {
  border: none !important;
  color: #969696 !important;
}

.anticon {
  vertical-align: 3px !important;
}

.ant-pagination-item-active {
  background: linear-gradient(93.98deg, #ffe185 6.58%, #f7ba00 105.53%);
  border-radius: 2px !important;
  border: none !important;
  color: #000000;
}

.ant-pagination-prev[aria-disabled="false"] .ant-pagination-item-link:hover,
.ant-pagination-next[aria-disabled="false"] .ant-pagination-item-link:hover {
  color: #000000 !important;
}

.ant-pagination-options-quick-jumper input:hover,
.ant-pagination-options-quick-jumper input:focus {
  border-color: #fcc216 !important;
  box-shadow: none !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #f2f2f2;
  border-radius: 2px;
  border: none !important;
}

.ant-select {
  font-family: "gm";
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.ant-select-arrow {
  color: #0e0e0e;
}

.ant-pagination-item-active a {
  color: #000000 !important;
}

.SQFT {
  font-family: gsb;
  font-size: 13px;
  background: #fff9e5;
  border-radius: 2px 0px 0px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 21%;
  margin: 0px;
  padding: 11px 0px 11px 0px;
  position: absolute;
  top: 22px;
  right: 4px;
}

/* From here for request details and material catalog */
.purchase_order_btn {
  width: 100%;
  cursor: pointer;
  color: #000;
  background: linear-gradient(93.98deg, #ffe185 6.58%, #f7ba00 105.53%);
  border-radius: 2px;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 15px;
  font-family: "gm";
}

.whatsapp_tag {
  background: #25d366;
  border-radius: 3px;
  cursor: pointer;
}

.separator_line {
  border-bottom: 3px dashed #e5e5ea;
}

.rs-picker-daterange-menu {
  z-index: 10000000000 !important;
}

.rs-picker-menu {
  overflow-y: scroll !important;
}

/* .rs-picker-daterange-panel {
  height: 200px !important;
} */
.rs-picker-toggle-wrapper {
  width: 100%;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 8px !important;
  padding-bottom: 9px !important;
  background: #f8f8f8 !important;
  text-align: start !important;
  font-family: Gm !important;
  border-radius: 0px !important;
}

.rs-picker-date.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle,
.rs-picker-daterange.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
  padding-right: 26px !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #000 !important;
}

.rs-picker-toggle-textbox {
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  left: 0px !important;
  top: 0;
  border: 1px solid transparent;
  padding-left: 10px;
  padding-right: 32px;
}

.material_table {
  background: #f8f8f8;
  border-radius: 2px;
  padding: 20px;
  font-family: Gsb;
}

.inputsearch_mat {
  background: #ffffff;
  border-radius: 1px;
  border: none;
  font-family: Gm;
  font-size: 12px;
  padding: 10px;
}

.inputsearch_mat:focus {
  outline: none;
}

.add_material {
  background: #fff;
  /* border: 0.99874px solid #d5d5d5; */
  border-radius: 2px;
  font-size: 12.5px;
  display: flex;
  align-items: center;
  padding: 10px;
  font-family: Gsb;
}

.add_mat_icon {
  color: #fcc200;
}

.upload_bulk_btn {
  font-size: 12.5px;
  display: flex;
  align-items: center;
  font-family: Gsb;
  gap: 5px;
  background: #f8f8f8;
  border-radius: 2px;
  padding: 13px;
  background-color: #fff2c8;
}

.modal-title_add_cat {
  font-family: Gb;
}

.new_edit {
  cursor: pointer;
}

.popup_delete_proj_icon {
  color: rgb(224, 65, 40);
  font-size: 50px !important;
}

.PO_cancel_icon {
  font-size: 50px !important;
  color: #f7b314;
}

.select_category_btn {
  background: linear-gradient(93.98deg, #ffe185 6.58%, #f7ba00 105.53%);
  border-radius: 2px;
  padding: 10px;
  font-family: gm;
  font-size: 12px;
  color: #000000;
  border: none;
  cursor: pointer;
}

.form-select {
  font-size: 0.9rem;
}

/*======================================= karan ============================================ */

.SelectDropDown_Con {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f8f8f8;
  border-radius: 2px;
  height: 45px;
  padding: 0px 8px 0px 8px;
  position: relative;
}

.SQFT {
  font-family: gsb;
  font-size: 13px;
  background: #fff9e5;
  border-radius: 2px 0px 0px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 21%;
  margin: 0px;
  padding: 11px 0px 11px 0px;
  position: absolute;
  top: 29px;
  right: 4px;
}

.g1 {
  font-family: gsb;
  font-size: 13px;
  background: #fff9e5;
  border-radius: 2px 0px 0px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 21%;
  margin: 0px;
  padding: 11px 0px 11px 0px;
  position: absolute;
  top: 29px;
  right: 4px;
}

.input_g1 {
  font-family: gsb;
  font-size: 13px;
  background: #fff9e5;
  border-radius: 2px 0px 0px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 21%;
  margin: 0px;
  padding: 11px 0px 11px 0px;
  position: absolute;
  top: 29px;
  right: 4px;
}

.dropDown {
  position: absolute;
  width: 100%;
  background-color: white;
  border: 1px solid ghostwhite;
  top: 40px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.slelectss:hover {
  cursor: pointer;
  background-color: #f8f8f8;
  width: 100%;
}

.scrolside:hover {
  height: 550px;
  overflow-y: scroll;
}

.scrolside::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.scrolside::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scrolside::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.scrolside::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.slelectss {
  text-align: center;
  width: 100%;
  border-bottom: 1px solid #f8f8f8;
  padding: 0px 0px 0px 0px;
}

.downloadme {
  cursor: pointer;
  width: fit-content;
  height: 45px;
  padding: 0px 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(93.98deg, #ffe185 6.58%, #f7ba00 105.53%);
}

.errorborder {
  border: 1px solid red !important;
}

.NoRecordFound {
  height: calc(87vh - 170px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* height: 400px; */
  background-color: #f8f8f8;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  /* background: -webkit-linear-gradient(top, #ffe185 1%, #f6ba00 100%); */
  border: 1px solid transparent;
}

.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: #8e8e93 !important;
  font-size: 14px !important;
  font-family: gm;
}

.rs-picker-toggle-caret {
  color: black !important;
}

.rs-picker-toggle:hover {
  border: 1px solid transparent !important;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  -webkit-box-shadow: inset 0 0 0 1px rgb(253, 217, 12) !important;
  -webkit-box-shadow: inset 0 0 0 1px var(--rs-input-focus-border) !important;
  box-shadow: inset 0 0 0 1px rgb(253, 217, 12) !important;
  box-shadow: inset 0 0 0 1px rgb(253, 217, 12) !important;
}

.rs-btn-link {
  color: rgb(19, 18, 17) !important;
  /* color: var(--rs-btn-link-text); */
  background-color: transparent;
  border: none;
  text-decoration: none !;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  color: #000000 !important;
  color: var(--rs-calendar-date-selected-text);
  background-color: rgb(253, 217, 12) !important;
  background-color: rgb(253, 217, 12) !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.rs-btn-primary {
  color: #000000 !important;
  color: var(--rs-btn-primary-text);
  background-color: rgb(253, 217, 12) !important;
  background-color: var(--rs-btn-primary-bg);
  border: none;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: black !important;
  color: black !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: black !important;
  color: black !important;
}

.wrap_img {
  background: #f9f9f9;
  padding: 10px;
  overflow-y: scroll;
  height: 87vh;
}

.imgg {
  height: 250px;
  width: 250px;
  object-fit: cover;
}

.loderimg {
  position: absolute;
  top: 50%;
  bottom: 0;
  right: 0;
  left: 50%;
  z-index: 2;
}

.version {
  position: absolute;
  bottom: 32px;
  font-family: gr;
  font-size: 13px;
  color: #909090;
}

.errorfile {
  border: 1px solid red !important;
}

.css-x19aeh-control {
  border: none !important;
  background: #f8f8f8 !important;
  border-radius: 2px;
  font-family: Gm;
}

/* progress bar section */
.progress_barsec {
  background: #f9f9f9;
  border-radius: 2px;
  padding: 18px;
}

.proj_status {
  font-family: Gsb;
  color: #000;
  font-size: 15px;
}

.project_note {
  background: #fff0e1;
  border-radius: 2px;
  color: #eb5757;
  padding: 20px;
  font-family: Gm;
}

.project_assigned_head {
  height: 5px;
  background: #f29239;
  border-radius: 2px 2px 0px 0px;
}

.popup_unlinkbankdetails_icon1 {
  color: #f29239 !important;
  font-size: 50px !important;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #f7b314 !important;
}

.react-select-container {
  height: 50px;
  /* Set the height to your desired value */
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #919191;
}

.custom-select__control {
  background-color: #f2f2f2;
  height: 50px;
  font-family: gm;
  font-style: normal;
  /* font-weight: 500; */
  font-size: 13px;
  line-height: 15px;
  color: #919191;
}

.custom-select__option {
  background-color: white;
  color: black;
}

.custom-select__option:hover,
.custom-select__option--is-selected {
  background-color: #f2f2f2;
  color: white;
}

.cendrolcom {
  background-color: #fde8b9;
  padding: 13px 11px;
  font-family: gsb;
  font-size: 12px;
  color: #000000;
}

.notepass {
  font-family: gsb;
  font-size: 14px;
  color: #000000;
}

.listiconround {
  background-color: gainsboro;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 6px;
  padding: 0px 5px;
}

.characters {
  font-family: gsb;
  font-size: 12px;
  color: #000000;
}

.listiconroundcurrect {
  background-color: greenyellow;
  width: fit-content;
  border-radius: 6px;
  padding: 0px 5px;
}

.success_msg {
  font-family: Gsb;
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 20px;
}

.accept_files {
  font-family: gm;
  margin-top: 10px;
  font-size: 15px;
}

.Download_link {
  cursor: pointer;
  text-decoration: underline;
}

.po_no {
  font-family: Gm;
}

.select_all_btn {
  background: #ffe185;
  border-radius: 2px;
  padding: 14px;
  color: #000;
  font-family: Gm;
}

/* css fixes */
.No_vendor_found {
  height: calc(100vh - 215px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* height: 400px; */
  background-color: #f8f8f8;
}

.css-b62m3t-container {
  font-family: Gm;
  width: 260px !important;
}

.css-b62m3t-container:focus {
  outline: none !important;
}

.css-1s2u09g-control {
  border: none !important;
  width: 100% !important;
  padding: 4px;
}

.css-b62m3t-container {
  width: 100% !important;
}

.css-1s2u09g-control {
  background-color: #fff !important;
}

.nete {
  /* background-color: #000; */
}

.dropdown_arrow {
  color: rgb(0, 0, 0);
  font-size: 20px;
  position: absolute;
  right: 30px;
  top: 38%;
  z-index: 99;
}
