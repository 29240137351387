.procurementUser {
  font-family: gb;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */

  color: #000000;
}
.ProcurementHeadtxt {
  font-family: gm;
  font-size: 12px;
  line-height: 14px;
  color: #8f8f8f;
}
/* sidebar start */
.cendrolImg {
  object-fit: contain;
  width: 25%;
}
.matrialTable {
  height: calc(100vh - 335px);
  overflow-y: scroll;
  margin-top: 15px;
}
.dashboardtab,
.dashboardtabactive {
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 14px 14px 14px 20px;
  gap: 10px;
}

.dashboardtab {
  background: #fefefe;
}
.dashboardtab:hover {
  background: linear-gradient(93.98deg, #ffe185 6.58%, #f7ba00 105.53%);
}
.dashboardtab:hover svg path {
  fill: #000000;
}

.ant-pagination-item:hover a {
  background: linear-gradient(93.98deg, #ffe185 6.58%, #f7ba00 105.53%);
  color: #000 !important;
}

.dashboardtabactive,
.dashboardtabactive:focus {
  background: linear-gradient(93.98deg, #ffe185 6.58%, #f7ba00 105.53%);
}

.dashboardtabactive svg path {
  fill: #0e0e0e;
}

.dashboardtabactive:hover svg path {
  fill: #0e0e0e;
}

.dashboardtabactive:focus svg path {
  fill: #0e0e0e;
}

.dashbordtablable {
  font-family: gm;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
}
/* sidebar start */

/* admin all prooject */
.admin_left_sidebar {
  /* background-color: #f3f3f3; */
  height: 90vh;
  padding-left: 25px;
}
.admin_side_menu_dashboardbtn_unactive {
  background: #fff;
  cursor: pointer;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  color: #000;
  font-family: gb;
  font-size: 14px;
  text-decoration: none;
}
.admin_side_menu_dashboardbtn_unactive:hover {
  text-decoration: none;
  color: #000;
}
.admin_inner_table_div {
  margin-top: 20px;
  background-color: #fff;
  /* padding: 15px; */
  border-radius: 5px;
}
.active {
  /* background-color: #af1818; */
  color: #fff;
}
.admin_table_outer {
  background-color: #f4f4f4;
  padding: 32px;
  border-radius: 20px;
}
.add_btn {
  background: #fff;
  border: none;
  color: #000;
  padding: 8px 15px 7px 20px;
  cursor: pointer;
  font-family: gm;
  border-radius: 5px;
}

.add_icon {
  color: #fcc314;
  cursor: pointer;
}
.delete_icon {
  color: red;
  font-size: 20px !important;
}
.crud_button {
  gap: 1px;
}
.add_project {
  color: #000;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 5px;
  border: none;
  padding: 10px 42px;
  font-family: gsb;
  text-decoration: none;
  cursor: pointer;
}
.add_project a:hover {
  text-decoration: none !important;
  color: #000;
}
.select_project_stage {
  width: 100%;
  height: 40px;
  border: 1px solid #ced4da;
  background: #f8f8f8;
  font-family: gm;
  font-size: 14px;
  border-radius: 0.25rem;
}
.select_project_stage:focus {
  outline: none;
}
.label_textfields,
.select_label {
  font-family: gm;
  font-size: 13px;
}
.addproject_input {
  height: 40px;
  font-family: gm;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.addproject_input:focus {
  outline: none;
}
.addpro_heading {
  font-family: gb;
}
.cendrol_plus_package {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 5px;
  border: none;
  color: #000;
  padding: 8px;
  width: 85%;
  font-size: 14px;
}
.premium_package {
  background: linear-gradient(
    108.2deg,
    #da9917 -14.5%,
    #ffff90 66.64%,
    #ecbf26 109.69%
  );
  border-radius: 5px;
  border: none;
  color: #000;
  padding: 8px;
  width: 85%;
  font-size: 14px;
}
.errors {
  color: #dc3545;
  font-family: gr;
}

.admin_projectdet_select {
  width: 80%;
  height: 35px;
  border: 1px solid #ced4da;
  background: #fff;
  font-family: gm;
  font-size: 14px;
  border-radius: 0.25rem;
}

.admin_project_edit {
  background: #f3f3f3;
  height: calc(90vh - 94px);
  padding: 20px;
  margin-top: 10px;
  border-radius: 10px;
}
.admin_project_edit1 {
  background: #f3f3f3;
  overflow-y: scroll;
  height: calc(90vh - 40px);
  padding: 20px;
  margin-top: 10px;
  border-radius: 10px;
}

.popup_delete_proj_icon {
  color: rgb(224, 65, 40) !important;
  font-size: 50px !important;
}

table {
  font-family: gm;
}
.doc_button {
  display: flex;
  align-items: center;
  /* background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%); */
  background-color: #f8f8f8;
  border-radius: 5px;
  border: none;
  padding: 10px 30px;
  font-size: 14px;
  font-family: gsb;
  font-size: 15px;
}
.doc_button:hover {
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
}
.admin_pro_delete {
  color: #fe3e3e;
  border: 1px solid #fe3e3e;
  padding: 6px 15px;
  background: #fff;
  border-radius: 5px;
  font-family: gr;
}
.admin_project_delete {
  color: #fe3e3e;
  border: 1px solid #fe3e3e;
  padding: 6px 15px;
  background: #fff;
  border-radius: 5px;
  font-family: gr;
  position: absolute;
  right: 10%;
  z-index: 9;
  top: 27px;
}
.delete_icon_admin {
  color: #fe3e3e;
  font-size: 20px !important;
}
.project_health_critical {
  background: #fffae9;
  border-radius: 2px;
  border: none;
  padding: 10px 25px;
  width: 170px;
  color: #fd7967;
}
.project_completed {
  background: #edfff7;
  border-radius: 2px;
  border: none;
  padding: 10px 25px;
  width: 170px;
  color: #3add5e;
}
.project_progressing {
  background: #f1fdff;
  border-radius: 2px;
  border: none;
  padding: 10px 25px;
  width: 170px;
  color: #3ee7fe;
}
.project_health_supcritical {
  background: #ffebeb;
  color: #fe3e3e;
  border-radius: 2px;
  border: none;
  padding: 10px 25px;
  width: 170px;
}
.project_health_progress {
  background: #ffcbb0;
  color: #000;
  border-radius: 2px;
  border: none;
  padding: 10px 25px;
  width: 170px;
}

.project_health_subcritical {
  background: #fffaec;
  color: #fcc314;
  border-radius: 2px;
  border: none;
  padding: 10px 25px;
  width: 170px;
}
.project_health_handover {
  background: #fff5ee;
  border-radius: 2px;
  border: none;
  padding: 10px 25px;
  width: 170px;
  color: #fc7716;
}
.project_new {
  background: #edfff7;
  border-radius: 2px;
  border: none;
  color: #33e8bd;
  padding: 10px 25px;
  width: 170px;
}
.file_upload_filename {
  position: absolute;
  top: 24em;
  left: 18em;
}
.admin_backarrow {
  font-size: 16px !important;
}
.add_contractor {
  color: #000;
  background: linear-gradient(162.29deg, #ffe185 -6.85%, #f7ba00 100.08%);
  border-radius: 5px;
  border: none;
  padding: 10px 42px;
  font-family: gsb;
  text-decoration: none;
  width: 100%;
  cursor: pointer;
}
.add_contractor a:hover {
  text-decoration: none !important;
  color: #000;
}
.contractor_details_input {
  padding: 6px;
  width: 100%;
  border-radius: 5px;
  font-family: gsb;
  background: #fff;
  border: 1px solid #ced4da;
}
.contractor_details_input:focus {
  outline: none;
}
.cont_details_heading {
  font-family: gsb;
}
.popup_addbankdetails_text {
  font-family: gsb;
}

/* labour */
.labour_served {
  background: linear-gradient(
    180deg,
    #64f985 -19%,
    #4adc6a 44.51%,
    #2aa946 108%
  );
  width: 140px;
}
.labour_details {
  padding-right: 30px;
  padding-left: 6px;
}
.Half_come {
  background: linear-gradient(162.29deg, #ffbf85 -6.85%, #f77600 100.08%);
  border-radius: 2px;
  padding: 10px;
  border: none;
  color: #fff;
  width: 50%;
}
.came {
  background: linear-gradient(
    180deg,
    #64f985 -19%,
    #4adc6a 44.51%,
    #2aa946 108%
  );
  border-radius: 2px;
  padding: 10px;
  border: none;
  color: #fff;
  width: 50%;
}
.not_came {
  background: linear-gradient(180deg, #ff3535 -10.87%, #af1818 111.12%);
  border-radius: 2px;
  padding: 10px;
  border: none;
  color: #fff;
  width: 50%;
}
.labour_details_box {
  background: #ffffff;
  box-shadow: 0px 13.497px 24.7444px -6.74848px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  padding: 18px;
}
.labour_ful_details {
  font-family: gsb;
}
.add_lab_row {
  background-color: #000;
  color: #fff;
  padding: 10px;
  font-family: Gm;
}
.suc_msg {
  font-family: "gsb";
}
.plus_icons {
  width: 24px;
  margin-right: 8px;
}

/* Schedule */
.labour_card_stats {
  background: #f9f9f9;
  border-radius: 3px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 28px;
  padding-right: 28px;
}
.accordion-item {
  background-color: #fff;
  border: none;
  margin-top: 15px;
}
.accordion-button {
  background-color: #f4f4f4;
}
.accordion-header {
  border: 1px solid #f5f5f5;
}
.schedule_subtask_heading {
  font-family: gsb;
  color: #000;
  font-size: 18px;
}
.accordion-button:not(.collapsed) {
  color: #000;
  background-color: #f5f5f5;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.pending_schedule {
  background: #fff5ed;
  color: #fe3e3e;
  width: 120px;
  text-align: center;
  font-family: gsb;
  border-radius: 3px;
  border: none;
  padding: 10px;
}
.main_task_sec {
  background-color: #fff;
  padding: 20px;
}
.addschedule_input {
  display: block;
  width: 100%;
  padding: 10px;
  /* padding: 0.375rem 0.75rem; */
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  padding: 10px;
  color: #212529;
  background: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.addschedule_input:focus {
  outline: none !important;
}
.rs-picker-daterange-menu {
  z-index: 10000000000 !important;
}
.rs-picker-menu {
  overflow-y: scroll !important;
}
/* .rs-picker-daterange-panel {
  height: 371px !important;
} */
.rs-picker-toggle-wrapper {
  width: 100%;
}
.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  background: #f8f8f8 !important;
  text-align: start !important;
  font-family: gsb !important;
}
.rs-picker-date.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle,
.rs-picker-daterange.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
  padding-right: 26px !important;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #000 !important;
}
.rs-picker-toggle-textbox {
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  left: 0px !important;
  top: 0;
  border: 1px solid transparent;
  padding-left: 10px;
  padding-right: 32px;
}
.schedule_pending {
  background: linear-gradient(97.19deg, #ff3535 -8.18%, #af1818 139.65%);
  border-radius: 5px;
  color: #fff;
  border: none;
  padding: 10px 20px;
}
.schedule_start {
  background: linear-gradient(277.69deg, #f47b08 0.23%, #ffc837 132.87%);
  border-radius: 5px;
  color: #fff;
  border: none;
  padding: 10px;
}
.schedule_completed {
  background: linear-gradient(
    180deg,
    #64f985 -19%,
    #4adc6a 44.51%,
    #2aa946 108%
  );
  border-radius: 5px;
  border: none;
  padding: 10px;
  color: #fff;
}

/* animation */
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #9880ff;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}
.custom_popup_background {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.25);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  /* visibility: hidden; */
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}
/*  */

.containerss {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999999 !important;
  /* pointer-events: none !important; */
}

.containerss .modal {
  z-index: 1;
  width: 800px;
  height: auto;
  background: white;
  display: flex;
  /* align-items: center;
  justify-content: center; */
  border-radius: 5px;
  position: relative;
  animation: animate 0.3s;
}
/* .modal button {
  position: absolute;
  top: 7px;
  right: 7px;
  padding: 8px 11px;
  background: crimson;
  color: white;
  font-weight: bold;
  border: none;
  outline: none;
  border-radius: 2px;
  cursor: pointer;
} */

@keyframes animate {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}
.project_details_checkbox {
  background-color: #f2f2f2;
  padding: 15px;
  font-family: gm;
  color: #000;
}
input[type="checkbox"] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  background: #ffffff;
  height: 20px;
  width: 20px;
  border: 1px solid #fcc216;
  box-sizing: border-box;
  border-radius: 2px;
}

input[type="checkbox"]:after {
  content: " ";
  background-color: #fcc216;
  color: #000000;
  height: 20px;
  width: 20px;
}

input[type="checkbox"]:checked:after {
  content: "\2714";
  padding: 3px 3px 0px 4px;
}
.AddProject {
  background: linear-gradient(93.98deg, #ffe185 6.58%, #f7ba00 105.53%);
  border-radius: 1px;
  padding: 7px 5px 7px 5px;
  font-family: gm;
  font-size: 12px;
  line-height: 14px;
  height: 38px;
  width: 122px;
  color: #000000;
}
.filterusers {
  background: #f9f9f9;
  border-radius: 1px;
  cursor: pointer;
}
.Filter {
  font-family: gm;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;

  /* Dark Gray */

  color: #000000;
}

/* add */
.InvoiceNumber_input {
  word-wrap: break-word;
  background: #f8f8f8;
  border-radius: 2px;
  border: none;
  outline: none;
  padding: 17px 11px;
  font-family: "gsb";
  font-size: 13px;
  line-height: 15px;
  color: #221e1f;
}

.file-input {
  display: inline-block;
  text-align: left;
  background: #fff;
  padding: 16px;
  width: 100%;
  position: relative;
  border-radius: 3px;
}

.file-input > [type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}

.file-input > .button {
  display: inline-block;
  cursor: pointer;
  /* background: #eee; */
  /* padding: 0px 16px; */
  border-radius: 2px;
  margin-right: 8px;
  float: right;
}

/* .file-input:hover > .button {
  background: dodgerblue;
  color: white;
} */

.file-input > .label {
  /* color: #333; */
  white-space: nowrap;
  opacity: 0.3;
}

.file-input.-chosen > .label {
  opacity: 1;
}

/*  */
.add_material_inputxt {
  background: #f8f8f8;
  border-radius: 2px;
  padding: 10px;
  color: #000;
  font-family: Gm;
  width: 100%;
  border: none;
  font-size: 13px;
}
.add_material_inputxt:focus {
  outline: none;
}
.add_material_main {
  padding: 0px;
}
.add_material_select {
  width: 100%;
  height: 47px;
  background: #f9f9f9;
  border-radius: 2px;
  color: #000;
  font-family: gm;
  font-size: 14px;
  border: none;
  padding-left: 11px;
}
.add_material_select:focus {
  outline: none;
}
.add_material_select1 {
  width: 100%;
  margin-top: 20px;
  height: 35px;
  border: 1px solid #ced4da;
  background: #fff;
  font-family: gm;
  font-size: 14px;
  border-radius: 0.25rem;
}
.unit_tag {
  background: #fff3cd;
  border-radius: 0px 2px 2px 0px;
  padding: 11px;
  /* margin-top: -8px; */
  width: auto;
  height: 46px;
  font-family: gm;
  font-size: 14px;
  color: #221e1f;
}
.Specification_title {
  font-family: gm;
  font-size: 14px;
  color: #221e1f;
}
.add_specif_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-family: Gm;
  background: #fff3cd;
  border-radius: 2px;
  padding: 10px;
  /* width: 60%; */
  cursor: pointer;
  /* margin-top: 1.5rem; */
}
.plus_icons {
  color: #fcc200;
}
.add_material_btn {
  background: linear-gradient(93.98deg, #ffe185 6.58%, #f7ba00 105.53%);
  border-radius: 2px;
  padding: 10px;
  font-family: Gm;
  color: #000;
  cursor: pointer;
}
.add_material_btn_long {
  background: linear-gradient(93.98deg, #ffe185 6.58%, #f7ba00 105.53%);
  border-radius: 2px;
  padding: 10px;
  font-family: Gm;
  color: #000;
  cursor: pointer;
  width: 100%;
}

.allProjectFilter {
  background-color: white;
  padding: 20px;
  width: 40%;
}
.allProjectFilter2 {
  background-color: white;
  padding: 20px;
  width: 70%;
}
.search_mat_parent {
  background-color: #fff;
}
.edt_mat_icon {
  width: 18px !important;
}
.label_txt_mat_details {
  color: #969696;
  font-family: Gm;
  font-size: 13px;
}
.material_details_txt {
  overflow-wrap: break-word;
  font-size: 16px;
  font-family: Gsb;
  color: #000;
}
.material_spec_table_header {
  color: #000;
  font-family: Gsb;
  background-color: #f6f6f6;
  padding: 12px;
}
.mat_spec_body {
  /* overflow-x: scroll; */
  word-wrap: break-word;
  background: #fff;
  color: #000;
  border: 1px solid #d7d7d7;
  font-family: Gm;
  font-size: 13px;
}
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.imgContainer {
  height: 550px;
  background: #f9f9f9;
  overflow-y: scroll;
}
.dashboard_select {
  font-size: 13px !important;
  background-color: #f9f9f9;
  font-family: "gm";
  border: none;
  padding: 10px 35px 10px 10px;
}
.notificationCount {
  background: rgba(255, 255, 255, 0.9);
  padding: 5px 6px;
  border-radius: 50%;
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #0e0e0e;
  margin-left: 5px;
}
.notificationCount1 {
  background: #f7b314;
  padding: 5px 8px;
  border-radius: 50%;
  font-family: gsb;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #0e0e0e;
  margin-left: 3px;
}
.nofificationAlert_container {
  padding: 15px;
  margin-top: 3%;
  width: 35%;
  background: #ffffff;
  border: 1px solid #dee2e6;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  /* height: 92px; */
}
.nofificationAlert_container_text {
  font-family: gsb;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
.CheckCircleOutlineIcon_container {
  background: #f5f5f5;
  border-radius: 8px;
  padding: 7px;
  margin-right: 10px;
}
.nofificationAlert_container_subtext {
  font-family: gsb;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: rgba(84, 89, 94, 0.6);
}
.CloseIcon_cover {
  background: #e5e5e5;
  border-radius: 29px;
  float: right;
  padding: 2px 3px;
  cursor: pointer;
}
